const contentData = {
    tourist:[
        {
            categoryTitle:'',
            articles:[
                {
                    title:'6 причин отдохнуть этой осенью в ОАЭ',
                    subInfo:'Контент для рекламної landing-page за напрямком ОАЕ',
                    content:`
    <p>Солнечная погода и теплое море — не единственные аргументы в пользу поездки в ОАЭ.
     Насладитесь всеми преимуществами арабской страны вместе с «Поехали с нами».</p>
    <p><strong>Удобные условия для туристов</strong></p>
    <p>Вернувшись из отпуска, вам не нужно сдавать тесты или самоизолироваться. <br>
    Перед поездкой туристам необходимо предоставить результаты ПЦР-теста, сделанного не позднее, чем за 96 часов до вылета. Все туроператоры, как правило, компенсируют стоимость диагностики, так что за дополнительные траты можно не переживать. А по прилете в ОАЭ с целью безопасности путешественников, им предлагается пройти еще один тест, совершенно бесплатно.
</p>
    <p><strong>Безупречный сервис и полная безопасность</strong></p>
    <p>Высокое качество обслуживания в гостиницах ОАЭ признано во всем мире. В период пандемии персонал в отелях следует всем санитарно-эпидемиологическим правилам и заботится о постояльцах. Проводит дезинфекцию общественных мест и номеров, контролирует соблюдение социальной дистанции и следит за самочувствием гостей. Отправляясь на отдых в Эмираты вы точно можете не переживать о своей безопасности.
</p>
    <p><strong>Отели на любой вкус и кошелек</strong></p>
    <p>Не каждая страна может похвастаться таким разнообразием гостиниц, как Эмираты. Курортные комплексы с анимацией и детским меню на берегу моря или апартаменты с панорамными окнами и роскошным интерьером на последних этажах небоскребов, отели в традиционном восточном стиле или же с минималистичным дизайном — независимо от ваших предпочтений, точно найдете вариант по душе! А высоким уровнем сервиса, вкусным питанием и безупречной чистотой порадуют даже бюджетные «‎трешки»‎. 
</p>
    <p><strong>Уникальные места, аналогов которым нет в мире</strong></p>
    <p>ОАЭ способны удивить самого искушенного путешественника. Ведь только здесь вы сможете насладиться великолепной панорамой на обзорной площадке самого высокого здания в мире, прогуляться по крупнейшему торговому центру или получить всплеск адреналина на аттракционах самого большого парка развлечений. В стране зарегистрировано более ста мировых рекордов и это далеко не предел. С каждым годом Эмираты подтверждают свой статус уникальной страны, впечатляя новыми достижениями. 
</p>
    <p><strong>Роскошный пляжный отдых</strong></p>
    <p>Шикарное побережье с белоснежным песком — главная роскошь ОАЭ. Все эмираты страны имеют выход к морю. И арабы лихо воспользовались этим даром природы. Большинство пляжей удостоены награды «Голубой флаг», что является гарантией чистоты, комфорта и безопасности. Понежиться на берегу под шум прибоя можно круглый год. Но поездка осенью — верный способ продлить себе лето.Туристов ждет солнечная погода, теплое море и развлечения на любой вкус — от водных горок до закрытых вечеринок с лучшими диджеями.
</p>  
    <p><strong>Атмосфера восточной сказки</strong></p>
    <p>ОАЭ поражает ультрасовременной архитектурой, высокими технологиями и арабской роскошью, но стоит свернуть на удаленную от туристического центра улочку — как попадете на колоритный рынок, пропитанный ароматом специй, пообщаетесь с местными жителями, которые чтут древние традиции и сможете ощутить настоящую атмосферу востока в одной из самых развитых стран мира.
</p> 
        <hr>
        <hr>
        <hr>
<p class="text-center"><strong><i>Идеальный курорт найдется для каждого</i></strong></p>
<p>ОАЭ — страна, которая может предложить идеальные условия для отдыха, независимо от ваших предпочтений. Планируете семейную поездку с ребенком? Рассмотрите Шарджу. Для дайвинга и снорклинга отлично подойдет Фуджейра, а за незабываемым шопингом и яркими снимками на фоне впечатляющих достопримечательностей отправляйтесь в Дубай. В Эмиратах найдется курорт на любой вкус! Озвучьте свои пожелания менеджеру и можете не сомневаться — мы организуем для вас отпуск мечты!
</p>

     <hr>
     <hr>
     <hr>
     <p class="text-center"><strong><i>6 аргументов, почему туристы выбирают «Поехали с нами»:</i></strong></p>
     
<p><strong>Крупнейшая сеть турагентств</strong></p>
    <p>Представительства «Поехали с нами» работают в любом уголке Украины, в том числе в небольших населенных пунктах. А еще вы можете приобрести тур, даже не выходя из дома — наш менеджер в онлайн-режиме подберет оптимальный вариант и поможет организовать идеальное путешествие.
</p>
     <p><strong>Забота о туристе 24/7</strong></p>
    <p>Наши клиенты могут рассчитывать на поддержку турагента с момента покупки путевки и вплоть до возвращения из отпуска домой. Мы оперативно решим любые вопросы, возникшие в путешествии — даже ночью.
</p>
     <p><strong>Выгодные условия</strong></p>
    <p>Мы делаем отдых доступным для каждого путешественника. На нашем сайте всегда найдете туры со скидками и уникальные акционные предложения. Нет возможности полностью оплатить путевку? Воспользуйтесь рассрочкой и разделите сумму на несколько платежей.
</p>
     <p><strong>Большой выбор предложений</strong></p>
    <p>Сотрудничество с ведущими туристическими операторами позволило нам сформировать огромную базу предложений на любой вкус. Оптимальный тур найдется для каждого!
</p>
     <p><strong>Только проверенные отели</strong></p>
    <p>Перед тем, как рекомендовать гостиницу клиенту, наши турагенты отправляются в инфо-тур, чтобы самостоятельно убедиться в качестве предлагаемого продукта.</p>
     <p><strong>Надежность и безопасность</strong></p>
    <p>Работаем только с проверенными партнерами, чтобы гарантировать туристу максимальный комфорт во время поездки.</p>
    <hr>
    <hr>
    <hr>
    
    
    <p class="text-center"><strong><i>Отзывы туристов:</i></strong></p>
    <p><strong>Лучшая гарантия того, что организованный нами отдых будет соответствовать вашим ожидания</strong></p>
    
        <p><strong>Наталья</strong> <i>27 октября 2020</i></p>
    <p>«Выражаю благодарность турагентству «Поехали с нами» за отличную возможность отдыхать с удовольствием. Особенно благодарю нашего менеджера, которая оперативно нашла наилучший вариант по нашему бюджету. Отдых великолепный! Спасибо Всей Вашей команде. Успехов и процветания!»
</p>
        <p><strong>Виктория</strong> <i>23 октября 2020</i></p>
    <p>«Осталась очень довольна услугами этого турагентства! Очень приятные и доброжелательные девушки, подберут тур исходя из ваших желаний и возможностей. Искренне и честно дали рекомендации по путешествию. Большое спасибо и обязательно вернемся.»
</p>
        <p><strong>Антон</strong> <i>13 октября 2020</i></p>
    <p>«Планировали отпуск в октябре и ни разу не пожалели, что выбрали это турагентство для сотрудничества! Менеджер подобрала идеальный вариант отеля, который полностью соответствовал нашим требованиям и был даже лучше, чем мы могли представить. Отдых прошел замечательно, турагент всегда был на связи. Также нас очень хорошо проконсультировали по курорту  — что посмотреть, куда сходить! Спасибо за замечательный отдых!»
</p>
        <p><strong>Владимир</strong> <i>05 октября 2020</i></p>
    <p>«Обратился с просьбой подобрать тур на определенные даты в определенный отель, вылететь нужно было через неделю, летели с двумя детьми. Итог: всё прошло на высшем уровне)) Большая благодарность сотрудникам за оперативную работу, за подбор удобного времени по вылетам, были с нами постоянно на связи. В дальнейшем обязательно будем обращаться к Вам ещё.»</p>
        <p><strong>Марина</strong> <i>30 сентября 2020</i></p>
    <p>«Не первый год пользуюсь услугами данного турагентства. Всегда остаюсь довольна качеством и профессионализмом его сотрудников. Отдельное спасибо менеджерам, за то, что хорошо выполняют свою работу :).»
</p>
        <p><strong>Анна</strong> <i>27 сентября 2020</i></p>
    <p>«Хорошее агентство, Впервые воспользовалась его услугами. Качеством сервиса полностью довольны, отлично организовывают отдых. Мне нравится, что можно рассказать о своих предпочтениях и тебе подберут хороший тур по твоим пожеланиям и сумме. Осталось много положительных эмоций. Спасибо.»</p>
        <p><strong>Елена</strong> <i>23 сентября 2020</i></p>
    <p>«Уже несколько лет езжу в туры, подобранные ребятами. Поддержка и сопровождение на очень высоком уровне, всегда на связи, подбираются и рекомендуются всегда хорошие отели. Очень приятно работать!»</p>
        <p><strong>Максим</strong> <i>21 сентября 2020</i></p>
    <p>«Летели на отдых за границу впервые. Поэтому вопросов и неясностей по поводу перелёта, выбора отеля, трансфера туда и обратно, страховки и т.д. было немного. Очень помогла во всем разобраться менеджер филиала, в котором покупали тур. Спасибо ей огромнейшее. Нам удалось избежать некоторых ошибок. Во многом благодаря «Поехали с нами», наш первый отдых за рубежом оказался даже лучше, ярче и приятнее чем ожидания!»
</p>
`,
                },
                {
                    title:'На гребне волны: почему путевка в Египет — отличный выбор для сёрфингистов?',
                    subInfo:'Стаття для зовнішнього ресурсу',
                    content:`
                    <p>Жаркая страна пирамид у многих ассоциируется с «ленивым» пляжным релаксом в комфортных отелях All Inclusive. Но любители экстрима знают: путевка в Египет — отличный выбор, если хочется зарядиться драйвом и к тому же выгодно отдохнуть у моря. В Египет приезжают, чтобы поплавать с аквалангом, покататься на внедорожниках по пустыне. А еще, чтобы «словить волну» и получить удовольствие от бушующего в крови адреналина. Специалисты турфирмы Turne.ua рассказали, где лучше всего «серфить» новичкам, и чего ожидать от местных курортов профи в водных видах спорта. 
</p>
    <p><strong>Трендовый серфинг</strong></p>
    <p>Сидячая работа и низкий уровень активности во время рабочих будней, мотивируют туристов к подвижным развлечениям на отдыхе. И серфинг как нельзя лучше справляется с этой задачей. Так что если вы задумались о том, чтобы встать на доску во время планового отпуска, советуем купить тур в Египет. Арендовать необходимое снаряжение и найти инструктора можно уже на месте.
</p>
<p>Серфинг, как и занятия другими видами спорта, способствует выработке в организме эндорфина и повышает жизненный тонус. А еще укрепляет здоровье, ведь во время активностей на воде задействованы практически все мышцы тела, и даже те, которые не работают во время стандартных тренировок в тренажерном зале. В дополнение: невероятное ощущение, что вам удалось покорить водную стихию, которое ни с чем несравнимо. Именно поэтому «поймав» волну один раз, многие экстремалы становятся заядлыми серфингистами.
</p>
 <p><strong>Где искать высокие волны в Египте?</strong></p>
 <p>Несмотря на то, что основным видом активного развлечения в Египте считается дайвинг, вы без труда найдете как минимум несколько школ серфинга и пунктов проката снаряжения. Лучшие отели Египта обладают собственными серф-центрами. Туристам предлагается заняться классическим серфингом, поскользить на доске с парусом (виндсерфинг), «прокатиться» по волнам с веслом (сапсерфинг) и даже проехаться за быстрым катером-буксировщиком (вейксерфинг).
</p>
<p><strong>Хургада</strong></p>
<p>Хургада — самое популярное место для обучения серфингу. Это достаточно ветреный регион с большим количеством серф-станций, в том числе и с русскоговорящими инструкторами. Практически в каждом районе курорта работают школы, где можно научиться управлять доской с нуля или довести навыки до совершенства. </p>
<p><strong>Дахаб</strong></p>
<p>Недорогой отдых в Египте в Дахабе привлекает многих туристов. В том числе и потому что серф-инфраструктура здесь активно развивается. Есть центры аренды снаряжения, школы, частные тренеры.</p>
<p>Особенность курорта — близкое расположение гор по отношению к воде. Ветер здесь набирает хорошую скорость и поднимает большие волны. Новичкам рекомендуют кататься в заливе, а опытные серферы улучшают свое мастерство уже в открытом море. 
</p>
<p><strong>Шарм-эль-Шейх</strong></p>
<p>Шарм-Эль-Шейх — еще один популярный курорт для занятий разными видами серфинга. Многие отели Шарма предлагают пробные уроки, продолжительные курсы для новичков и опытных спортсменов, прокат оборудования.</p>
<p>Среди всех мест для серфинга в Шарме, лучше всего подойдет бухта Набк, где у берегов довольно ветрено, дно песчаное, а глубина небольшая. Кроме этого, Набк является заповедной зоной, поэтому здесь нет опасного моторного транспорта.</p>
<p><strong>Лучшее время для водных активностей</strong></p>
<p>Недорогие путевки в Египет появляются в феврале-марте и ноябре-декабре, в начале и конце туристического сезона. На большинстве курортов, этот период как раз считается идеальным временем для экстремальных активностей на воде. Ведь если пляжному отдыху ветер только мешает, то спортсменам на доске он гарантирует высокие волны и заряд яркими эмоциями. </p>
<p>Цена тура в Египет в «сезон серфинга» будет зависеть также от выбранного отеля, продолжительности и целей вашего отдыха. Например, если вы хотите стать профи «с нуля», есть смысл приобрести путевку на 10-14 дней и записаться на интенсивный курс серфинга. </p>

                    `
                },
                {
                    title: 'Covid-19: актуальные условия въезда для туристов ',
                    subInfo: `
                    SEO-стаття для посадкової сторінки:
                    <a href="https://turne.ua/otkrytye-strany-covid-19" target="_blank">COVID-19: Інформація</a>
                    `,
                    content: `
                    <p>
                    Названный COVID-19 штамп коронавирусной болезни внес немалые коррективы в туристическую отрасль.
                     Границы многих государств с весны 2020 недоступны для въезда иностранцев в целях безопасности. 
                     Отправиться на отдых теперь можно только в <b>открытые страны для граждан Украины</b>. 
                     Мы подготовили для путешественников актуальную информацию об условиях въезда по популярным 
                     туристическим направлениям.
</p>
                    <p><strong>Отдых на море в летний сезон 2021</strong></p>
                    <p>
                    По истечении более года с момента закрытия границ практически во всех государствах ЕС, некоторых странах Азии, Африки, Америки, туристическая отрасль постепенно начинает возвращаться в привычный режим, адаптировавшись к новым реалиям. Курортные страны, в преддверии пляжного сезона, смягчают условия для путешественников, а турагентства регулярно радуют своих клиентов новостями об открытии все большего количества направлений. 
                    На сегодняшний день,  <b>коронавирусные правила въезда в страны ЕС</b> позволяют отдохнуть в Греции, Болгарии, на Кипре, при условии предоставления отрицательного результата ПЦР-теста, сделанного за 72 часа до вылета. В числе других традиционно «летних» курортных направлений с минимальными ограничениями — Тунис, Албания, Черногория, Хорватия.
                    <b>Новые правила въезда в страны</b>разрешают также туристам помимо справки с негативными показателями наличия COVID-19, предъявить оригинал сертификата о прохождении полного курса вакцинации препаратами Pfizer и AstraZeneca, Covishield и Sinovac. 
                    
</p>
<p>
И самое приятное, <b>открытые страны для украинцев для отдыха на море</b> включают любимую многими нашими соотечественниками Турцию. Причем, согласно постановлению правительства восточного государства, с 15 мая 2021 года въезд для граждан Украины свободный, без тестов и самоизоляции. О безопасности можно не переживать. На всех пляжных курортах гостей принимают лишь те отели, которые получили сертификат Safe Tourism, подтверждающий реализацию необходимых гигиенических и анти-COVID-19 мероприятий в целях сохранности здоровья отдыхающих.

</p>
<p><strong>Другие популярные направления с минимальными ограничениями</strong></p>
<p>Приглашают на отдых граждан нашей страны открытые для украинцев популярные экскурсионные и рекреационные направления. Так, с отрицательным показателями ПЦР-тестирования, сделанного за 48, 72 или 96 часов до рейса (в зависимости от условий конкретного направления) разрешено посещать Иорданию, Грузию и Сербию. Отдохнуть можно в популярных Египте и Объединенных Арабских Эмиратах. Доступна и жаркая экзотика — Кения, Танзания (остров Занзибар), Доминиканская Республика, Мексика, Бразилия, Мальдивские острова. Вместе с тем, в отдельных случаях, 
    <b>правила въезда в открытые страны</b> 
    предполагают прохождение диагностики не только перед пересечением границы, но и уже во время пребывания на курорте. Так, например, если хотите отправиться на Шри-Ланку, будьте готовы сдать дополнительный тест непосредственно по прилете в аэропорту в Коломбо, и сделать еще одно тестирование на седьмой день отдыха на Цейлоне. Детальную информацию об особых условиях путешествия в некоторые открытые страны вам предоставят менеджеры turne.ua.
</p>
<p><strong>Закрытые для въезда страны</strong></p>
<p>Строгие  <b>условия въезда в страны с учетом Covid-19</b>действуют во всем мире. Некоторые государства и вовсе запретили украинцам посещать свою территорию без специального разрешения или вида на жительство до изменения эпидемиологической ситуации. Среди них — члены Шенгенской зоны (Испания, Италия, Германия, Чехия, Дания, Эстония, Франция, Бельгия и др.), Индонезия, Индия, часть стран Северной, Северо-Западной и Западной Африки.
</p>
<p><strong>Возвращение домой</strong></p>
<p>
По приезде из государств «красной зоны» украинцы обязаны пройти 14-дневную самоизоляцию с использованием приложения «Дій. Вдома», либо сдать ПЦР-тест и получить негативный результат. При возвращении из «зеленых» стран карантинных ограничений нет.

</p>
                    `,
                },
                {
                    title: 'Подбираем горящие путевки на Балканы: Топ-3 курортных направления',
                    subInfo: 'Стаття для зовнішнього ресурсу',
                    content: `
                    <p>Невероятные Балканы — «полуостров шести морей»: Черного, Ионического, Адриатического, Эгейского, Мраморного и Критского. Для пляжного отдыха — поистине райское место. Греция и Турция признаны самыми жаркими и популярными направлениями Балкан. Хорватия полюбилась европейцам. А среди путешественников из стран СНГ один из наиболее частых запросов в летний сезон — горящие путевки в Болгарию, Албанию или Черногорию. Неудивительно, ведь климат здесь мягкий (столбик термометра редко превышает отметку +30 °С), море чистое, а туристическая инфраструктура развита на высшем уровне. Об особенностях «золотой тройки» нам рассказали специалисты тревел-портала Poehalisnami.kz.
</p>
                    <p><strong>Демократичная Болгария</strong></p>
                    <p>Европейская страна, где русскоязычному путешественнику будет максимально комфортно. И не только из-за обилия отелей, пляжей и развлечений. Болгарский входит в группу славянских языков, да и старшее поколение здесь свободно разговаривает по-русски. А значит сложностей в коммуникации с местным населением не возникнет. Еще один несомненный плюс Болгарии — цена отдыха. Стоимость экскурсий, товаров в магазинах и блюд в ресторанах ниже среднеевропейской и сравнима с московской. За несколько евро можно купить и сувениры: продукцию из роз, сладости, амулеты-мартеницы. А если «поймать» выгодные горящие туры, то в целом поездка получится очень доступной.</p>
                    <p>Болгария компактная. Например, если вы решили провести свой отпуск в Солнечном Берегу, добраться до большинства других популярных курортных зон можно не более, чем за полчаса езды на автомобиле. Так за 7-10 дней отдыха путешественники осматривают достопримечательности Свети-Власа, Елените, Обзора, Поморие, Бургаса, Несебра. Причем даже в «знойный» июль посещать экскурсии вполне комфортно, ведь температура воздуха составляет максимум +32 °С. А Черное море чистое и теплое.
</p>
                    <p><strong>Живописная Черногория</strong></p>
                    <p>Если любите захватывающие дух пейзажи и размеренную атмосферу — подбор туров в Черногорию станет отличным решением. Страна по размеру почти в 3 раза меньше Московской области, но одарена природой с лихвой. Местные курорты гармонично приютились между величественных гор и густых лесов, глубоких озер и чистого Адриатического моря. В дополнение — европейский уровень сервиса, отличные условия для оздоровительного отдыха и мягкий климат. Столбик термометра в летнюю пору удерживается на отметках +26…+31 °С, вода в море прогрета до +23…+26 °С. 
</p>
                    <p>Куда бы вы не осуществили поиск туров в Черногорию, ее «скромная» площадь позволяет ознакомиться со всеми природными и историческими богатствами сполна, получить незабываемые впечатления и сотни самых атмосферных фото. 
</p>
                    <p><strong>Недооцененная Албания</strong></p>
                    <p>Популярность Албании, как туристического направления, только набирает обороты. Основная причина, по которой путешественники хотят сюда купить горящую путевку — отличный отпуск за небольшую цену. Местные курорты еще не избалованы туристами, поэтому для иностранцев албанцы стараются обеспечить лучшие условия для первоклассного отдыха. 
</p>
                    <p>Локацию для отпуска стоит выбирать в зависимости от своих предпочтений. Тирана, столица страны, не имеет выхода к морю, но славится множеством достопримечательностей, В Шенджине шикарные ресторанчики с морепродуктами и пляжи с пологим входом в воду. Саранда — оживленный городок с разнообразием баров и ночных клубов. В Дурресе, помимо песчаного побережья, туристов ждет интересная экскурсионная программа. А чтобы отдохнуть на двух морях одновременно, стоит найти тур во Влеру. Курорт расположен на стыке Адриатического и Ионического морей, пляжи здесь разделены бухтами.</p>
                    `
                },
                {
                    title: 'Отель Ideal Prime Beach Hotel',
                    subInfo: 'SEO-опис готелю',
                    content: `
                    <p>
                    <b>Идеал Прайм Бич</b> — роскошный пятизвездочный отель, с очень «выигрышным» расположением: частный песчано-галечный пляж в непосредственной близости, а знаменитая улица Баров Мармариса с многочисленными вечерними развлекательными заведениями всего в 3 км. Расстояние к аэропорту Даламан составляет 98 км.</p>
                    <p>
                    Конечно, в первую очередь, один из самых тусовочных курортов страны, привлекает поклонников активной ночной жизни, готовых «зажигать» под ритмы современных хитов до рассвета.
                    Но <b>туры в Ideal Prime Beach Hotel</b> станут отличным выбором также для семей с детьми, влюбленных парочек и дайверов. В Мармарисе несколько десятков уникальных локаций для изучения подводной жизни, которые подойдут как для опытных ныряльщиков, так и для новичков. Есть аквапарки и дельфинарий, где будет весело и интересно малышам, а также красивая набережная протяженностью в 12 км для романтических променадов вдоль моря.

</p>
<p>Полностью обновленный в 2015 году, отельный комплекс располагается на площади 20000 кв. м. В четырех 6-этажных жилых корпусах обустроены номера типов Standard и Suite. Любители расслабиться в джакузи выбирают King Suite или Prime Suite.</p>
<p>Взглянув на
<b>фото отеля Ideal Prime Beach Hotel</b> на turne.ua, можно заменить, что комнаты обладают свежим ремонтом, оформлены в европейском стиле и оснащены всем необходимым для комфортного отдыха: кондиционером, телевизором со спутниковыми каналами, сейфом, мини-баром, чайником, феном. Есть балкон или терраса с видом море, окрестности или бассейн. Уборка производится ежедневно.
 
</p>
<p>Для дополнительного удобства постояльцев, на территории гостиницы присутствуют:</p>
<ul>
<li>круглосуточная стойка регистрации;</li>
<li>бесплатный Wi-Fi в лобби и общественных местах;</li>
<li>обмен валюты;</li>
<li>лифты;</li>
<li>камера хранения;</li>
<li>кабинет врача;</li>
<li>конференц-залы; </li>
<li>салон красоты;</li>
<li>прачечная.</li>
</ul>
<p>
Для купания в отеле обустроен большой бассейн с фонтаном. Малышам выделена отдельная секция с водными аттракционами и тремя горками. Рядом можно расслабиться на шезлонге, принимая солнечные ванны. А если хотите максимального релакса — к вашим услугам шикарный SPA-центр с массажными и косметологическими кабинетами, крытым бассейном, сауной, хамамом. Посещение платное, 
<b>цены в Ideal Prime Beach Hotel</b> на процедуры SPA вам подскажут на ресепшене.
</p>
<p>
Туристы, предпочитающие проводить отпуск более активно, могут посетить спортзал с современными тренажерами, поиграть в пляжный волейбол, настольный теннис, дартс, бильярд. После захода солнца гостей ждут шоу-программы с живой музыкой и выступлениями местных артистов.
</p>
<p>
Концепция Ультра все включено предполагает комплексное трехразовое питание с сервировкой «шведский стол», и легкие перекусы в течение дня. Оставляя
<b>отзывы о Ideal Prime Beach Hotel</b>,  гости отмечают: голодными точно не останетесь. Меню в главном ресторане разнообразное, а блюда вкусные. В перерыве между основными приемами пищи можно заказать снеки, спиртные и безалкогольные напитки в двух барах. На территории отеля работает заведение A la Carte с интернациональной кухней.
</p>
<p>Всего в нескольких минутах ходьбы от корпусов расположен просторный частный песчано-галечный пляж с двумя небольшими понтонами. На берегу для постояльцев — шезлонги, зонты, полотенца. При заходе в воду встречаются камни, поэтому советуем позаботиться о наличии специальной обуви для комфортного купания.</p>

                    `
                },
                {
                    title: 'Курорты солнечной Греции: куда поехать?',
                    subInfo: 'SEO-текст для посадкової сторінки: «курорти Греції»',
                    content: `
                    <p>Высокий 
                    <b>рейтинг курортов Греции</b>, как туристического направления, легко объясним. Ведь страна может предложить насыщенный и увлекательный досуг туристам с разными предпочтениями. Здесь шикарные пляжи соседствуют с оливковыми рощами, уникальные античные памятки с современными центрами развлечений, а одновременно вкусные, полезные и сытные греческие блюда не оставят равнодушными даже самых привередливых гурманов.            
</p>
<p>
Государство на юге Европы включает более 1500 островов, которые омываются Средиземным, Эгейским, Ионическим морями.
<b>Лучшие курорты Греции </b>
расположены на Родосе, Крите и Миконосе. Не менее популярны остров Санторини, полуостров Халкидики и столица страны — Афины.
</p>
<p><strong>Остров Крит</strong></p>
<p>Живописный Крит входит в список «самые популярные морские курорты Греции», ведь это крупнейший остров страны. Крит делится на 4 области: Ираклио, Ханья, Лассити и Ретимно. От выбора района зависит, на каком пляже будете проводить время. Западное побережье около Ханьи и Ретимно радует туристов песком, а в южной части преобладают галечные зоны отдыха.</p>
<p>Крит считают родиной чудовища Минотавра, поэтому знаменитый Кносский дворец с лабиринтом — «must see». Внимания заслуживают также Пальмовый лес, Самарийское ущелье, Диктейская пещера, монастырь Аркади.</p>
<p>
По отзывам туристов, лучшие отели острова сосредоточены в его северной части. 
<b>Основные курорты Греции</b>
на Крите редко предлагают систему «все включено». В основном в гостиницах предусмотрена концепция BB (завтрак) или HB (полупансион). Отдыхая на греческом курорте, обязательно загляните на ужин в местную таверну – здесь очень атмосферно.

</p>
<p><strong>Остров Родос</strong></p>
<p>Второй по популярности остров после Крита порадует мягким климатом — пляжный сезон длится вплоть до октября, а солнце светит более 300 дней в году. Омывается Родос сразу двумя морями — Средиземным — здесь на побережье преобладают песчаные пляжи, а волн почти не бывает, и Эгейским вдоль которого расположены города Иксия и Ялиссос — излюбленные места виндсерферов. Из популярных вариантов досуга на Родосе, помимо релакса на пляже: дайвинг, занятия водными видами спорта, посещение древних памяток (именно здесь была расположена статуя Колосс Родосский — одно из Семи чудес Древнего Мира).
</p>
<p><strong>Остров Санторини</strong></p>
<p>
Представляя <b>островные курорты Греции</b> , турагентства предлагают путешественникам яркие тревел-буклеты с красивыми фото сказочных домиков вдоль моря — именно белоснежные фасады с синими крышами являются визитной карточкой Санторини. Сделать запоминающиеся селфи на фоне необычной архитектуры, встретить закат или отдохнуть на пляже Перисса с песком черного цвета, можно как приехав на пароме на один день (к примеру, с о. Крит), так и предварительно забронировав номер в отеле на весь период отпуска. Однако учитывайте, что Санторини считается одним из самых дорогих греческих курортов.
</p>
<p><strong>Полуостров Халкидики</strong></p>
<p>Многие туристы, рассматривая для летнего отдыха материковые курорты Греции, отдают предпочтение Халкидики — полуострову необычной «трехзубой» формы, на севере Эгейского моря.
</p>
<p>Молодежь и тусовщики выбирают городок Кассандру. Калифея подойдет семьям с ребенком, а Ситония — для любителей элитного отдыха.</p>
<p>Из Халкидиков удобно добираться к горе Олимп, святыням Метеоры, городу Салоники. Легендарные монастыри горы Афон располагаются в районе Халкидиков — Агион Орос.
</p>
<p><strong>Афины</strong></p>
<p>
По количеству легендарных исторических достопримечательностей, Афины превосходят <b>популярные курорты Греции для пляжного отдыха.</b>
Столица Греции магнитом притягивает ценителей античности. Городу стоит посвятить отдельную поездку. Просто чтобы осмотреть достопримечательности потребуется несколько дней, не говоря уже о времени на шопинг, для знакомства с блюдами национальной кухни, изучения местной культуры.

</p>
<p>Главная историческая ценность — Парфенон — основная святыня древних Афин. Внимания заслуживают и храм Гефеста, археологический музей, Национальная художественная галерея, руины храма Зевса Олимпийского. А за сувенирами отправляйтесь в районы Плака, Патисьон и Монастираки.
</p>
                    `,
                },
                {
                    title: 'Обучение туризму: топ-4 профессии, где не нужно высшее образование',
                    subInfo: 'Стаття для зовнішнього ресурсу',
                    content: `
                    <p>
                    «Окончить школу с отличием, освоить специальность в университете, устроиться на работу» — еще 30 лет назад такой сценарий был беспроигрышным для построения успешной карьеры. Сегодня же — все больше людей не видит смысла тратить 4-5 лет на получение диплома. Приобрести необходимую теорию и практические знания, подтвержденные сертификатом, можно на коротких специализированных курсах всего за пару месяцев или даже недель
</p>
<p>
Если вы увлечены сферой путешествий, но не знаете, какие вакансии просматривать, эксперты компании Poehalisnami.ua предлагают полезную подборку. В ней — популярные профессии, не требующие проходить длительное обучение туризму. Возможно, одна из них — и есть ваша мечта?!
</p>                    
<p><strong>Бортпроводник</strong></p>
Список требований к бортпроводникам внушительный: «возраст до 35 лет, приятная внешность, крепкое здоровье и хорошая физическая подготовка, знание иностранного языка». Зато иметь на руках подтверждение соответствующего высшего образования необязательно. Приступать к работе можно, окончив специальные
<b>курсы в сфере туризма</b> , продолжительностью от 3 до 6 месяцев. Необходимо сдать экзамены и пройти стажировку, предложенную учебным заведением. В карьерных перспективах стюарда или стюардессы — рост до начальника службы или инструктора.
 <p><strong>Аниматор</strong></p>     
 <p>Профессия аниматор — новая, но очень востребованная. Ведь для трудоустройства не нужно обладать специфическими навыками. Достаточно иметь задор, жизнелюбие, коммуникабельность и любовь к творчеству. Большинство аниматоров — активные студенты, желающие подработать во время летних каникул, как раз, когда высокий пляжный сезон. Дополнительным плюсом будет опыт работы с детьми, умение петь или танцевать.</p>  
 <p><strong>Менеджер по туризму</strong></p>             
<p>Увлекаетесь географией, умеете убеждать и хотите дарить людям положительные эмоции? Тогда самое время присмотреть хорошие
<b>курсы для менеджеров по туризму.</b> В процессе обучения вы узнаете специфику курортных направлений, правила обработки клиентских запросов, принципы оформления документов, секреты успешных продаж. А после — сможете без проблем устроиться в турагентство. Многие компании предлагают работу кандидатам, окончившим
<b>курсы по туризму</b>, но еще не получившим опыт. Особенно, если будущий сотрудник открытый и общительный, грамотно выражает свои мысли и быстро решает конфликтные вопросы.</p>                    
 <p>Однако имейте в виду: на пути будут встречаться не только благодарные клиенты. Подбор путевок для некоторых приверед может превратиться в настоящий квест, даже если прошли 
 <b>обучение на менеджера по туризму</b> с отличием. Профессия требует внимательности, находчивости, стрессоустойчивости.
</p> 
<p><strong>Гид</strong></p> 
<p>
Между специализациями гид и экскурсовод возникает много путаниц. Ведь существует распространенное мнение, что это лишь разные названия одной профессии. На самом деле, их нужно четко разделять. В спектре обязанностей экскурсовода: водить туристов по достопримечательностям и рассказывать о них по заготовленной программе. Гид, в свою очередь, является проводником путешественника в незнакомой стране — встречает в аэропорту, сопровождает к отелю, решает конфликтные вопросы, сообщает о всех изменениях.
<b>Обучение турагентов</b>-гидов обычно предлагают туроператоры. Высшее образование не требуется. А вот клиентоориентированность, коммуникабельность, стрессоустойчивость — обязательны для карьерных успехов. Кроме того, будьте готовы к длительным командировкам. Если вы хорошо владеете иностранным языком, можете пройти
<b>обучение на турагента</b>-переводчика и получить работу с более высокой ставкой.
</p>
                    
                    `
                },
                {
                    title: 'Почему туристические франшизы становятся трендом',
                    subInfo: 'Стаття для зовнішнього ресурсу',
                    content: `
                    <p>
                    Несмотря на то, что туристические франшизы — не новинка на мировом рынке, с каждым годом они набирают все больших оборотов. Секрет популярности прост: это бизнес, который не требует внушительного стартового капитала, быстро окупается и не имеет границ для роста. К тому же — пакетные туры — всегда востребованный продукт. Даже когда спрос на поездки за границу был снижен, возросла популярность внутреннего туризма, что позволило сохранить высокий уровень прибыльности. Эксперты турпортала Poehalisnami.ua рассказывают, почему открыть 
                    <b>турагентство по франшизе</b> лучшая идея для начинающих предпринимателей.
</p>
<p><strong>Выгода покупки туристической франшизы</strong></p>
<p>
<b>Франчайзинг в туризме</b>  — это ведение своего дела, но при этом без рисков и экспериментов. Работая под известным брендом, вы являетесь независимым предпринимателем, управляете собственным штатом сотрудников и не ограничены в применении собственных подходов для увеличения продаж. При этом, получаете дополнительные привилегии от франчайзера:
</p>
<ul>
<li>консультации от гуру в туристическом бизнесе на этапе открытия;</li>
<li>оптимизированный сайт и рекламное продвижение;</li>
<li>личного наставника на протяжении сотрудничества;</li>
<li>клиентскую базу;</li>
<li>доступ к CRM-системе;</li>
<li>лояльность клиентов — бренд уже имеет определенный статус, он на слуху и завоевал доверие путешественников.</li>
</ul>
<p>
Основной бонус — приобретение успешной модели ведения бизнеса. Никаких проб и ошибок — вы следуете готовому подробному плану, отлаженному до мелочей за годы наработок турфирмы.
</p> <br>
<p><b>Особенности сделки</b></p>
<p>
Возникает вопрос: если <b>франшиза в туризме</b> открывает столько преимуществ предпринимателю, какая выгода для франчайзера? Для получения прав на ведение бизнеса под известным брендом, требуются стартовые вложения (в зависимости от условий конкретной компании, как правило — от 100 000 грн) — часть капитала является паушальным взносом франчайзеру, остаток суммы покрывает расходы на покупку офисного инвентаря, техники, обустройство рабочего пространства. Кроме того, 
<b>франчайзинг по туризму</b> подразумевает ежемесячную «арендную» плату за пользование товарным знаком — роялти. Размер процента устанавливается индивидуально каждой турфирмой, но обычно позволяет предпринимателю выйти «в плюс» уже в первый год ведения собственного дела.
</p>
<p><b>Требования к франчайзигу:</b></p>
<ul>
<li>чтобы  <b>открыть туристический бизнес с нуля</b>,  нужно иметь расчетный счет в банке, банковские гарантии, ЭЦП;
 </li>
<li>франчайзи занимается предпринимательской деятельностью как ФЛП, по 3-й группе ЕН;</li>
<li>при заключении сделки подписывается договор, на основе которого покупатель обязан придерживаться стандартов бизнес-модели;</li>
<li>оплата налогов — за франчайзи.</li>
</ul>
<p><strong>Можно ли «прогореть»?</strong></p>
<p>
Если собираетесь <b>открыть туристический бизнес</b> и изучаете риски, помните главное правило: франчайзеру выгодно, чтобы вы успешно продавали. Поэтому в случае низкого процента сделок, компания всячески способствует достижению лучших результатов. Заручившись поддержкой опытного коуча, вы будете анализировать причины каждой незакрытой продажи, получать дельные советы, посещать тренинги, 
<b>стоимость </b> которых уже оплачена компанией. При работе под известной маркой, шансы «банкротства» практически равны нулю даже в случае «простоя» всей отрасли. Например, если путешествия по некоторым направлениям стали невостребованы, ввиду огромного опыта, турфирмы быстро находят новые возможности для монетизации бизнеса, соответственно и для бесперебойного потока клиентов франчайзи.
</p>

                    
                    
                    `
                }

            ]
        },
        {
            categoryTitle:'Статті у блог',
            articles:[
                {
                    title: 'Ночная жизнь в Одессе',
                    subInfo: 'Сезон ярких пляжных вечеринок открыт! Если у вас идеальный летний отпуск ассоциируется с громкой музыкой, зажигательными танцами до рассвета, шумом и весельем на набережной — Одесса точно не разочарует...',
                    content: 'https://www.poehalisnami.ua/blog/nochnaya-zhizn-v-odesse',
                    link:true,
                },
                {
                    title: 'Старинная крепость Аккерман в Белгород-Днестровском',
                    subInfo: 'Планируя летние каникулы на черноморском побережье, многие туристы заранее изучают на карте интересные локации поблизости выбранного курорта, куда можно отправиться на экскурсию...',
                    content: 'https://www.poehalisnami.ua/blog/starinnaya-krepost-akkerman-v-belgorod-dnestrovsko',
                    link:true,
                },
                {
                    title: 'Как подготовиться к путешествию?',
                    subInfo: 'Организация отпуска иногда приносит не меньше удовольствия, чем сам отдых. А если заранее изучить общие правила подготовки к поездке, собрать чемодан и завершить рабочие дела, дни в предвкушении долгожданного путешествия пролетят еще быстрее...',
                    content: 'https://www.poehalisnami.ua/blog/kak-podgotovitsya-k-puteshestviyu',
                    link:true,
                },
                {
                    title: 'Солнечная Сицилия: 5 мест которые необходимо посетить туристу',
                    subInfo: 'Сицилия – живописный остров на юге Италии, который пользуется популярностью среди туристов. О достопримечательностях Сицилии читайте в нашей статье...',
                    content: 'https://www.poehalisnami.ua/blog/solnechnaya-sitsiliya--mest-kotorie-neobhodimo-pos',
                    link:true,
                },
                {
                    title: 'Путешествуем по Кипру на машине: какие города посетить?',
                    subInfo: 'Кипр — небольшой, но очень колоритный остров, со множеством интересных мест. И хотя экскурсионные бюро здесь практически на каждом шагу, лучший способ изучить новую страну в полной мере — поехать на машине по Кипру...',
                    content: 'https://turne.ua/articles/kipr-na-mashine-2079',
                    link:true,
                },
                {
                    title: 'Что купить в Турции, что привезти? ',
                    subInfo: 'Для многих наших соотечественников летние каникулы в Турции стали привычным делом — короткий перелет, развитая туристическая инфраструктура и удобная система All Inclusive. Но главный аргумент большинства любителей Турецкой Ривьеры — бюджетные цены — как на туры, так и на развлечения, еду, сувениры...',
                    content: `
                    <p>
                    Для многих наших соотечественников летние каникулы в Турции стали привычным делом — короткий перелет, развитая туристическая инфраструктура и удобная система All Inclusive. Но главный аргумент большинства любителей Турецкой Ривьеры — бюджетные цены — как на туры, так и на развлечения, еду, сувениры. Шопинг здесь обойдется дешевле, чем в польских ТЦ, а по качеству местная продукция ничуть не уступает европейским брендам.
                    Что же <b>что привезти из Турции</b> на память себе и в подарок близким, помимо банальных магнитов и статуэток? Специалисты тревел-портала «Поехали с нами» составили вкусный, полезный и практичный shopping list.
</p>
<p><strong>Изделия из кожи</strong></p>
<p>
Турция наравне с Италией — главный поставщик кожаных изделий по всему миру. Причина наличия множества фабрик по пошиву продукции из кожи легко объяснима: в восточной стране на высоком уровне развито животноводство, и местные предприниматели не нуждаются в завозе импортного сырья. Оклад у местных работников небольшой. Соответственно,
 <b>цены на базарах</b> и в ТЦ на качественный товар устанавливаются относительно низкие. В каждой курортной зоне вы найдете аллеи торговых прилавков с модными аксессуарами из натуральной кожи. И хоть зачастую нет никакого подвоха в стильной сумочке за 40-50 USD, вы можете проверить подлинность изделия прямо на месте — при выборе кожаной продукции, ее нужно слегка потереть. Отсутствие следов свидетельствует о его хорошем качестве.
</p>
<p><strong>Лекарства</strong></p>
<p>Пожалуй, самый странный совет для тех, кто интересуется «что стоит купить в Турции?». Тем не менее, отдыхая на морских курортах, туристы не упускают возможность отправиться на шопинг…в местные аптеки. Причина тому — эффект турецких медицинских препаратов не хуже чем у европейских аналогов, а цена доступнее.
</p>
 <p>Конечно, ваши друзья вряд ли оценят пару упаковок «Парацетамола» в качестве «морского» сувенира, а вот свою домашнюю аптечку можете пополнить.
Выбирайте местные лекарства с известным вам действующим веществом и проконсультируйтесь с фармацевтом. В любой курортной аптеке найдется специалист, владеющий английским или русским языком. Имейте в виду: фармацевтический рынок находится под жестким контролем государства, так что рецептурные аналоги, даже при наличии выписки доктора, вам не продадут. 
</p>  
<p>Помимо традиционных лекарств, в Турции широкий выбор хорошей фитопродукции и лечебных косметических средств.</p>                 
  <p><strong>Восточные деликатесы</strong></p>   
      <p>
      О знаменитых турецких сладостях — лукуме, пахлаве, халве, знают даже те, кому еще не посчастливилось побывать на местных курортах. Что уж говорить о заядлых путешественниках, ежегодно отправляющихся на
      <b>шопинг в Турции</b> во время морских каникул. И если ваши друзья попросили внести разнообразие в традиционный сувенирный набор из привычных лакомств, порадуйте их одним из следующих аппетитных подарков:
</p>  
<ul>
<li>Сыр. Мало кому известно, что Турция — страна истинных сыроедов. Местные жители едят его с хлебом, лепешками, оливками, свежими овощами, подают в качестве закусок и добавляют в основные блюда. Представлено более 150 сортов традиционного сыра — выдержанного твердого, мягкого белого, пряного и без добавления специй. Лучше продегустировать несколько видов и выбрать по вкусу. Но помните: кисломолочный продукт следует покупать перед самым отъездом домой, тщательно упаковав его в алюминиевую пищевую фольгу. 
</li>
<li>Мед. Универсальный вариант для тех, кто не знает <b>что купить в Турции </b> вкусного для приверженцев здорового питания. Самый «медовый» турецкий регион (он же популярный курорт) — Мармарис. Причем, помимо привычного цветочного, турки производят каштановый, цитрусовый, сосновый и другие необычные виды меда.</li>
<li>Фисташки. Настоящий деликатес для многих — то, <b>что можно купить в Турции</b> по очень бюджетной цене. Местные фисташки отличаются крупным размером, желтоватым оттенком и отменным вкусом. Кроме того, орехи обладают большим сроком годности, поэтому станут практичным вкусным сувениром для гурманов.</li>
<li>Паста «Месир». Фитопаста — выбор тех, кто желает привезти поистине уникальные съедобные <b>сувениры из Турции.</b> Ведь изготавливают этот продукт только здесь. Помимо приятных вкусовых качеств, Месир обладает лечебными свойствами, ведь в составе — лимон, десятки видов трав и пряностей.</li>
</ul>
<p><strong>Натуральная косметика</strong></p>   
<p>Если не знаете, <b>что стоит привезти из Турции</b>  в качестве подарка для подруги, сестры или мамы, смело делайте ставку на местные косметические средства и не прогадаете. В их основе — органический состав и старинные восточные рецепты красоты. Фавориты покупок — розовая вода, грязевая глина для масок и обертывания, ароматические масла, мыло, бальзамы для волос и кожи с натуральными ингридиентами. </p>


         `
                },
                {
                    title: 'Есть ли акулы в Турции?',
                    subInfo: 'Отдых на курортах Турции неизменно пользуется популярностью у жителей стран Европы. Бюджетные цены, множество исторических мест, удобная система All Inclusive, и главное — благоустроенное побережье с чистым, теплым морем. И не одним...',
                    content: `
                    <p>
                    Отдых на курортах Турции неизменно пользуется популярностью у жителей стран Европы. Бюджетные цены, множество исторических мест, удобная система All Inclusive, и главное — благоустроенное побережье с чистым, теплым морем. И не одним. Восточная страна омывается четырьмя морями — Черным, Мраморным, Эгейским и Средиземным. У последних двух расположились популярные туристические локации с сотнями отелей на первой линии. Многие туристы, планирующие свое первое путешествие на один из местных курортов, справедливо интересуются:
                    «<b>есть ли акулы в Турции?</b>» Ведь во время отпуска за границей важны не только комфортные условия, но и максимальная безопасность. Менеджеры туристического агентства «Поехали с нами» тщательно изучили этот вопрос и составили честный
                    <b>гид по акулам на побережье Турции.</b>
</p>
<p><strong>Что нужно знать об акулах в Турции: краткий ликбез</strong></p>
<p>Средиземное море, омывающее юг и запад Турции, считается самым крупным и открытым из прилегающих акваторий — оно покрывает территорию 2 500 000 кв. км. Впечатляет также количество морских обитателей — в глубинных и поверхностных слоях встречается более 550 видов рыб и млекопитающих. Среди них есть и акулы. Однако повода для паники нет. Даже самые крупные экземпляры хищников питаются исключительно рыбой, крабами и моллюсками и не подплывают близко к суше — в прибрежных водах, из-за активно развитой рыболовной отрасли, их главного источника пищи крайне мало. Статистика подтверждает: за последние 100 лет от акульих зубов погибло всего 32 человека. Речь идет о всей акватории, а турецкие берега — лишь ее маленькая часть.
</p>
<p>
Сами турки утверждают, что агрессивных хищников в море не видели годами, тем не менее для туристов, которые задаются вопросом: «насколько 
<b>Анталия безопасна, когда дело доходит до акул?</b> » — устанавливают сетки, не позволяя рыбам заплывать в зоны купания. Специальные ограждения есть во всех популярных туристических городах.

</p>
<p>
Вероятность встретить хищных обитателей в других морях, омывающих Турцию, практически равна нулю.
</p>
<p>
<b>На заметку:</b> В окрестностях Мармариса, в заливе Гекова, есть акулий питомник. Он считается вторым в мире по количеству обитающих хищных особей. В спокойные воды бухты рыба приплывает для размножения. Однако залив надежно огражден и не представляет никакой опасности для отдыхающих.
</p>
<p><strong>Как избежать встречи с акулами?</strong></p>
<p>Несмотря на то, что в последние годы нападения акул у турецких берегов не зафиксированы, соблюдать разумные меры безопасности все же нужно:</p>
 <ul>
 <li>воздержитесь от плавания в случае открытых ран на теле. Запах крови хищники ощущают за несколько сотен метров;</li>
 <li>не купайтесь в темное время суток вдали от берега. Ночью акулы могут подплывать близко к пляжам в поисках рыбы;</li>
 <li>занимайтесь дайвингом только в специально отведенных для этого местах — дайв-сайтах. Погружаться на большую глубину лучше всего в тандеме с опытным инструктором, который хорошо знает местность. Иначе рискуете самостоятельно узнать
 <b>есть ли акулы в Средиземноморье.</b> Если же во время изучения морского мира, вы неожиданно увидели зубастого хищника, ни в коем случае не паникуйте и не привлекайте внимание — спокойно следуйте в сторону берега;
 </li>
 <li>в открытом море стоит плавать вдали от рыболовных судов. Рабочие иногда выбрасывают за борт отходы, привлекательные для хищников;</li>
 <li>перед купанием в открытом море, лучше не надевать яркие украшения. Акул привлекает блеск, также они видят контрастные цвета, например оранжевый.</li>
 </ul>   
  
                    
                    `
                },


            ]
        }
    ],




    med:[
        {
            categoryTitle:'',
            articles:[
                {
                    title:'Аллергия',
                    subInfo:'SEO-опис захворювання',
                    content:`
                    <p>Аллергия ‒ это повышенная чувствительность организма к определенному аллергену,
возникающая вследствие реакции иммунной системы при непосредственном контакте с ним.
Аллергенами могут служить продукты питания, растения, насекомые, пыль, химические
ингредиенты и другие, безвредные для окружающих, вещества.</p>
<p><strong>Как распознать аллергию?</strong></p>
<p>Симптомы аллергии напрямую зависят от конкретного раздражителя и могут затрагивать
носоглотку, дыхательные пути, кожные покровы, пищеварительную систему, другие органы.
Наиболее распространенные проявления аллергической реакции:</p>
<ul>
<li>Появление сыпи и покраснение кожи</li>
<li>Насморк, чихание, припухлость носовых пазух</li>
<li>Регулярно повторяющийся сухой ночной кашель</li>
<li>Воспаление глаз</li>
<li>Появление отеков разной локализации</li>
</ul>
<p>Самым опасным симптомом возникновения аллергии считается анафилактический шок ‒ реакция
немедленного типа, которой характерны внезапное появление зуда, приступы удушья, резкое
снижение артериального давления, бледность и повышенное потоотделение. Чаще всего
возникает вследствие приема лекарств, укуса насекомых или членистоногих. Подобная реакция
организма может угрожать жизни и требует срочной медицинской помощи.</p>
<p><strong>Как распознать аллерген?</strong></p>
<p>Залогом успешного лечения заболевания является своевременное обращение к врачу-
аллергологу. Для определения точной причины возникновения аномальной реакции организма,
пациенту назначается комплексное обследование с обязательным проведением <y>пробы на
аллергию</y>.</p>
<p><b>Существует несколько методов выявления раздражителя:</b></p>
<p>
<b><y>Кожные пробы</y></b> ‒ простая и безболезненная процедура, которую широко применяют для быстрого
определения аллергена. Суть данного <y>анализа на аллергию</y> заключается в нанесении на кожу
потенциальных раздражителей, после чего пациенту делают маленькие царапины
(скарификационный метод) или поверхностные уколы (прик-тест). Результаты обследования
известны в течение получаса. Покраснение и припухлость более 3 мм на месте прокола считается
положительной реакцией на аллерген. <br><br>
<b>RAST-тест</b> ‒ используется, как предварительный метод диагностики, определяющий дальнейшее
направление исследований. У пациента берут кровь из вены, после чего материал помещают в
несколько пробирок с растворами, содержащими определенные аллергены. Далее специалистом
вычисляется количество антител. При их повышенном количестве, назначаются дополнительные
лабораторные исследования .<br>
<b>Анализ крови на имунноглобулин</b> ‒ проводится в случае положительной реакции RAST-теста и
позволяет наиболее точно определить раздражитель. Доктор производит забор крови, которую
потом смешивают с конкретными аллергенами трех групп: ингаляционной (бытовая пыль, шерсть
животных, пыльца растений и т.д.), контактной (косметика и средства для уборки) и пищевой
(различные продукты питания). После этого образцы крови исследуют и вычисляют иммунный
ответ. Реакция может быть низкой, средней и высокой. Повышенные показатели подтверждают
аллергическую реакцию на то, или иное вещество. <br>
<b>Провокационные пробы.</b>  Если результаты <y>анализа крови на аллергию</y> и кожных проб остаются
сомнительными, единственным вариантом, позволяющим выявить возбудитель является
провокационное обследование. В организм пациента вводится возможный аллерген в

зависимости от места развития прежней реакции ‒ раствором капают глаза, носовую полость или
дают проглотить. Далее специалист фиксирует наличие или отсутствие реакции. Процедура
проводится под строгим наблюдением лечащего врача. В случае наличия симптомов аллергии,
исследование прекращают и проводят необходимые меры по их устранению.
</p>
<p><strong>Как подготовиться к проведению анализа на аллергию?</strong></p>
<p>
<b>Проведение</b> <y>пробы на аллергию,</y> как и другие обследования, требует от пациента
предварительной подготовки:
</p>
<ul>
<li><b><y>Анализы на аллергию лучше сдавать, когда</y></b> наблюдается период ремиссии ‒ это
гарантирует наиболее точный результат</li>
<li>За неделю до обследования следует отказаться от приема антигистаминных и
успокоительных медикаментов</li>
<li>За несколько дней до проведения <b><y>анализа на пищевые аллергены,</y></b>
пациенту нужно
исключить из рациона шоколад, орехи, цитрусовые, яйца, морепродукты, мед, несезонные
фрукты и овощи.
</li>
</ul>
<p>
Не стоит проходить аллергопробы при простуде, повышенной температуре, заболеваниях
желудочно-кишечного тракта и других патологиях в период обострения. Также, существуют
ограничения к проведению обследования <y>у детей</y> до 5 лет ‒ малышам не делают кожные пробы и
выявить аллергию только при помощи анализа крови.
</p>
<p><strong>Где <y>сдать анализы на аллергию в Киеве?</y></strong></p>
<p>
Узнать информацию о том, <y>где можно сдать аллергопробы в Киеве</y> и других городах Украины,
<y>сколько стоит</y> интересующий вид анализа, а также подобрать наиболее подходящую клинику для
прохождения обследований поможет сервис DOC.ua. На сайте можно ознакомиться с
месторасположением медицинского центра, графиком работы врачей-аллергологов, посмотреть
какова <y>цена пробы на аллергию,</y> а также прочитать реальные отзывы посетителей, которые уже
прошли обследование. Оставить заявку на проведение <y>анализа на аллергию в Киеве</y> или регионе
вы можете круглосуточно онлайн ‒ наш сотрудник свяжется с вами в ближайшее рабочее время,
уточнит данные, и при необходимости предоставит всю интересующую информацию: <y>как точно
называется анализ,</y> время приема доктора, какие документы необходимо при себе иметь и другие
важные детали.
</p>
                    `,
                },
                {
                    title:'Медицинская справка на права',
                    subInfo:'SEO-опис послуги',
                    content:`
                    <p>Медсправка на права ‒ документ, подтверждающий возможность водителя управлять
транспортным средством. Медсправку для водителей в Киеве или другом регионе
Украины необходимо предъявить для допуска к квалификационному экзамену по
вождению в МРЭО, при восстановлении водительского удостоверения в связи с его
утерей или изменении личных данных.</p>
<p>Успешное прохождение медицинской комиссии перед получением прав исключает у
пациента наличие заболеваний, которые могут быть потенциально опасными во время
вождения автомобиля и привести к дорожно-транспортному происшествию или другим
неприятным последствиям.</p>
<p>Получить водительскую справку можно в государственном медицинском учреждении или
частной клинике с наличием лицензии на оформление документов в соответствии с
требованиями МОЗ Украины.</p>
<p><strong>Другие обстоятельства, при которых может потребоваться
медицинская справка для водителей:</strong></p>
<ul>
<li>Зачисление на работу на должность водителя.</li>
<li>Восстановление прав в случае их лишения по решению правовых органов.</li>
<li>Прохождение запланированного технического осмотра.</li>
<li>Оформление протокола сотрудником дорожной полиции при ДТП.</li>
<li>Открытие новых категорий.</li>
<li>Оформление водительских прав нового международного образца.</li>
</ul>
<p>Водителям, работающим в компании по перевозке пассажиров или груза, необходимо
ежегодно проходить медкомиссию, а также осмотр доктора перед и после рейса. При
этом, неважно, трудоустроен автомобилист в крупном предприятии, государственной
структуре, либо же в частной фирме.</p>
<p class="text-center"><strong>Что нужно, чтобы оформить справку для водителей?</strong></p>
<p>Форма 083/о ‒ это официальный бланк, где указываются личные данные водителя,
категория прав, ограничения к управлению каким-либо типом транспорта, а также
медицинские рекомендации к вождению. Например, в случае патологических показателей
остроты зрения, водителю запрещено управлять автомобилем без корректирующих очков
или контактных линз.</p>
<p><strong>Чтобы пройти медкомиссию в Киеве, необходимо предъявить следующие
документы:</strong></p>
<ul>
<li>паспорт;</li>
<li>2 фотографии;</li>
<li>военный билет (для мужчин);</li>
<li>заключения нарколога и психиатра;</li>
<li>водительское удостоверение (в случае наличия).</li>
</ul>
<p class="text-center"><strong>Что включает в себя медицинский осмотр?</strong></p>
<p><strong>Чтобы получить медсправку, водителю нужно посетить следующих специалистов:</strong></p>
<ul>
<li>терапевта или семейного врача;</li>
<li>хирурга;</li>
<li>окулиста;</li>
<li>отоларинголога;</li>
<li>невролога;</li>
<li>акушера-гинеколога (для женщин).</li>
</ul>
<p>Для успешного оформления справки, пациент должен также предъявить результаты
электрокардиографии и лабораторных исследований, которые включают в себя:</p>
<ul>
<li>показатели содержания сахара в крови</li>
<li>общий анализ крови и мочи, а также результаты лабораторной диагностики на
группу крови и резус-фактор</li>
</ul>
<p>Результаты диагностики и заключения специалистов вносятся в форму медсправки 083/о,
после чего она подписывается терапевтом.</p>
<p><strong>Где можно быстро оформить справку для водительских прав?</strong></p>
<p>Поиск клиники, которая имеет лицензию на выдачу медстравки формы 083/о в Киеве или
другом регионе, а также прохождение медицинской комиссии может занять несколько
дней или даже больше. Сервис DOC.ua поможет оформить нужный документ с
максимальной экономией вашего времени. На сайте можно ознакомиться со списком
клиник, которые занимаются оформлением формы 083/о, посмотреть цену на
оформления медсправки в Киеве и других городах Украины, график работы и
месторасположение медицинских учреждений, а также отзывы пациентов, которые
прошли осмотр для оформления справки для водительских прав.</p>
<p>Оставляйте заявку онлайн и в ближайшее рабочее время сотрудник call-центра свяжется
с вами для уточнения деталей, подскажет точную цену оформления документа и
согласует дату и время посещения клиники.</p>
                    `,
                },
            ]
        },
        {
            categoryTitle:'Статті у блог',
            articles:[

                {
                    title:'Паниковать нельзя: первая помощь при высокой температуре у ребенка',
                    subInfo:'Когда у ребенка поднимается высокая температура тела, многие родители начинают паниковать, хватаясь за любые, часто неверные, способы сбить жар. Как правильно измерять температуру и что необходимо сделать до приезда доктора, чтобы облегчить состояние малыша, рассказываем далее...',
                    content:`
<p>Когда у ребенка поднимается высокая температура тела, многие родители начинают паниковать,
хватаясь за любые, часто неверные, способы сбить жар. Как правильно измерять температуру и
что необходимо сделать до приезда доктора, чтобы облегчить состояние малыша, рассказываем
далее.</p>
                    <p><strong>Почему происходят изменения температуры тела и какие
бывают способы ее измерения?</strong></p>
<p>Температура тела – комплексный показатель состояния организма, отражающий взаимосвязь
между выработкой тепла внутренних органов и теплообменом между ними и внешней средой.
Нормальная температура у здорового ребенка обычно от 36 до 37 °С. Однако эти цифры могут
быть выше или ниже, в зависимости от возраста, образа жизни, индивидуальных физиологических
особенностей малыша или способа измерения температуры.</p>
<p>Для определения нормальной температуры у ребенка, ее требуется измерять утром, в обед и
вечером на протяжении нескольких дней. Во время процедуры малыш должен быть спокойным и
расслабленным – это гарантирует наиболее верные показатели.</p>
<p><strong>Существует три основных способа измерения температуры:</strong></p>
<ul>
<li><b>В подмышечной впадине.</b>Наиболее распространенный, удобный и в тоже время самый
длительный метод. Для того, чтобы получить точные цифры, кожа должна быть сухой, а
сам термометр необходимо плотно удерживать в подмышечной области в течение 7-10
минут. Нормальными, в данном случае, будут показатели от 36 до 37,3 градусов.</li>
<li><b>В прямой кишке.</b> Чаще всего применяется малышам до 4 лет и предполагает собой более
высокие значения нормы, нежели обычно. Температура тела у детей может достигать
отметки до 38 градусов и при этом не является аномальной. При ректальном измерении,
кончик градусника предварительно смазывают увлажняющим кремом или маслом и
вставляют в задний проход примерно на 1 минуту.</li>
<li><b>Во рту.</b> Широко распространенный метод определения температуры у Европейцев, в
Украине применяется крайне редко. При использовании орального способа, кончик
градусника достаточно положить под язык на 3-5 минут. Нормой будет считаться цифра
36,6-37,5 градусов. Измерять температуру сублингвально следует только электронным
термометром.</li>
</ul>
<p>Не стоит забывать, также, и про суточные колебания температуры тела организма. Самая низкая
отметка на градуснике будет ранним утром, а наивысшая ‒ в 16–17 часов. Изменения
температуры на протяжении дня у детей до 5 лет могут быть до 1°С, старше 5 лет – до 0,5°С. Если
ребенок прекрасно себя чувствует, это не требует никакой коррекции.</p>
<p><strong>Основные причины повышения температуры у малыша</strong></p>
<p>
<b>Перегревание.</b>У детей раннего возраста процессы терморегуляции еще не сформированы.
Поэтому температура тела может изменяться на 1-2 градуса без видимых причин. Особенно это
заметно у младенцев до года, которые часто перегреваются от неправильно подобранной
одежды, пеленания, длительного нахождения на открытом солнце. Эта категория детей также
чаще замерзает, что проявляется икотой, похолоданием конечностей, иногда даже их
синюшностью.
</p>
<p>
<b>Вирусные инфекции.</b> Существует большое количество бактерий и вирусов, которым подвержены
только дети. Это могут быть возбудители острых респираторных инфекций, отоларингологических
или кишечных заболеваний. Часто, в таких случаях очень сложно выявить недуг самостоятельно, а
температура тела будет подниматься только через некоторое время после возникновения
инфекции в организме. Поэтому очень важно внимательно следить за состоянием малыша и при
первых признаках недомогания обратиться к специалисту.
</p>
<p>
<b>Прорезывание зубов.</b> Если жар сопровождается капризами, беспокойством, плачем,
повышенным слюноотделением, ребенок берет в рот посторонние вещи, то, скорее всего, у
малыша начинают резаться зубки. Визуально оценить состояние десен родителям достаточно
сложно, а вероятность занесения инфекции значительно возрастает. Правильно осмотреть
ротовую полость и установить верную причину повышения температуры сможет врач-педиатр.
</p>
<p><strong>Какие бывают виды лихорадки и когда следует сбивать
жар?</strong></p>
<p>Повышение температуры тела – это защитно-компенсаторная реакция организма, в результате
которой усиливается иммунный ответ на фактор, ставший причиной ее изменения. При
повышении температуры тела более 38,5°С различают два типа лихорадки:</p>
<ul>
<li>«Розовая», при которой кожа, адекватно реагируя на гипертермию, становится розовой
или красной, теплой и влажной.</li>
<li>«Белая» – кожа приобретает «мраморный» оттенок, становится холодной на ощупь
вследствие спазма периферических сосудов, жар практически всегда сопровождается
нарушением поведения ребенка в виде его беспокойства или наоборот, вялости и апатии.
Такие симптомы свидетельствуют о неблагоприятном развитии болезни.</li>
</ul>
<p>Всемирная Организация Здравоохранения рекомендует снижать температуру выше 39°С.
Исключением являются малыши до 3 лет, а также «группа риска», в которую входят дети с
заболеваниями центральной нервной системы, сердечно-сосудистой системы, с врожденными
пороками развития, онкологические больные. При наличии у ребенка сопутствующих патологий,
сбивать необходимо температуру выше 38°С.</p>
<p><strong>Основные действия родителей до приезда доктора при повышении температуры тела у
ребенка:</strong></p>
<ul>
<li>Регулярное измерение температуры.</li>
<li>Поддержание нормального температурного режима (до +20–21°С) и влажности (до
60–70%) в помещении, где находится ребенок.</li>

<li>Применение физических методов охлаждения в виде наложения влажных салфеток
(смоченных водой) на магистральные сосуды в области подмышечных впадин,

подколенных ямок, голеней, области печени, головы. При «белой» лихорадке применять
обтирание запрещено.</li>
<li>Достаточный питьевой режим поможет избавиться от токсинов, которые образуются в
процессе жизнедеятельности вирусов, а также немного сбить жар у ребенка. Поить лучше
всего теплым чаем с лимоном или малиной, морсом, компотами с высоким содержанием
витамина С. Сладких и газированных напитков при высокой температуре следует избегать.</li>
<li>Не стоит настаивать на приеме пищи. Если у малыша нет аппетита, обед лучше заменить
обильным питьем.</li>
<li>Родителям очень важно соблюдать тесный психоэмоциональный контакт с ребенком,
исключая панику. Почитайте больному книгу, включите мультфильм или поиграйте в
спокойные игры, однако лучше всего, чтобы ребенок поспал и набрался сил.</li>
</ul>
<p>В домашней аптечке обязательно должны быть жаропонижающие препараты в виде сиропов,
ректальных суппозиториев или таблеток. Однако давать детям лекарственные средства стоит
только после консультации с педиатром. При приеме медикаментов самостоятельно, родители
должны четко следовать рекомендациям, которые прописаны в инструкции к их применению,
отталкиваясь от симптомов, возраста и наличия хронических заболеваний у малыша.</p>
<p>Помните, повышение температуры тела у ребенка может быть симптомом опасных заболеваний и
требует незамедлительной консультации со специалистом.</p>
                    `,
                },
                {
                    title:'Когда идти к трихологу: самые распространённые проблемы с волосами',
                    subInfo:'Густая и красивая шевелюра для многих являются едва ли не главной составляющей красоты и привлекательности. И хотя выпадение волос ‒ процесс безболезненный, он способен вызвать серьёзный дискомфорт, как у мужчин, так и у женщин разных возрастов, а иногда быть причиной серьезных патологий, которые требуют незамедлительного обращения к специалисту...',
                    content:`https://doc.ua/news/articles/kogda-idti-k-trihologu`,
                    link:true,
                },
                {
                    title:'Найти и обезвредить: как вылечить синдром поликистозных яичников',
                    subInfo:'Синдром поликистозных яичников – одно из самых распространенных гормональных расстройств, которое способно причинить немало трудностей и проблем. По данным научных исследований, заболевание встречается у каждой пятой женщины в мире и является наиболее распространённой причиной сбоев менструального цикла и генеративной функции, заставляющей обратиться за медицинской помощью...',
                    content:`https://doc.ua/news/articles/najti-i-obezvredit-kak-vylechit-sindrom-polikistoznyh-yaichnikov`,
                    link:true,
                },
            ]
        }
    ],


    zoo:[
        {
            categoryTitle:'',
            articles:[
                {
                  title: ' Бомбейская кошка',
                  subInfo: 'SEO-опис породи',
                  content: `
                  <p><strong>Общие характеристики и родина породы</strong></p>
                  <p>Гладкая черная шерсть, большие янтарные глаза, грация и изящество — внешне
бомбейская кошка очень напоминает мини-пантеру, однако ничего общего с диким
хищником она не имеет и обладает спокойным и мягким характером.
Несмотря на свое название, данная порода кошек была выведена в Америке, в 50-х
годах XX века путем скрещивания бурманской и американской короткошерстной
кошки, и никаким образом с Индией не связана.</p>
<p>Бомбейские кошки обладают средними размерами, удлинённым изящным корпусом и
хорошо развитой мускулатурой. Глаза большие, широко поставлены медно-оранжевого цвета. Конечно же, отличительная черта бомбейской кошки — чистый
антрацитово-черный окрас от кончика носа до подушечек на лапках. Густая, короткая и
гладкая шерсть бомбея имеет красивый блеск и внешне напоминает атлас.</p>
<p>Знатоки данной породы кошек, утверждают, что удивительное сочетание красивой
внешности, гипнотизирующего взгляда и непревзойденной индивидуальности,
способно расположить к себе любого человека, даже не кошатника. Спокойные и
неконфликтные бомбейские кошки обожают ласку, могут часами нежится на руках у
своего хозяина и будут везде ходить за вами «хвостиком». В меру игривые и активные,
они предпочтут улице домашний уют, нормально уживаются с другими животными, но
могут недолюбливать маленьких детей из-за их шалостей и баловства.</p>
<p>Родина породы: США</p>
<p><strong>Питание и уход за породой</strong></p>
<p>Бомбейские кошки, как правило, домоседы, с умеренной активностью и отменным
аппетитом. Эти питомцы могут съесть все содержимое тарелки, независимо от
размера порции и будут просить добавку. Из-за такой любви к еде у них бывают
проблемы с лишним весом. Чтобы грациозная мини-пантера не превратилась в
черного колобка, уделите рациону любимца особое внимание.</p>
<p>Кормите питомца высококачественной готовой продукцией с пониженным
содержанием жиров в сухом или консервированном виде. Такой корм зачастую уже
имеет указания касательно норм кормления, что поможет вам соблюдать правильное
количество порций и не перекормить питомца. Если вы предпочитаете готовить
самостоятельно, выбирайте нежирное мясо телятины, кролика, индейки или курицы,
дополняя рацион субпродуктами, творогом, крупами и овощами.</p>
<p>Короткая, шелковистая шерсть бомбея не требует особого ухода, достаточно будет
раз в неделю расчесывать питомца резиновой щеткой и регулярно купать.</p>
<p><strong>Возможные болезни и необходимые превентивные меры</strong></p>
<p>У питомцев данной породы могут встречаться врожденные болезни сердца, проблемы
с дыханием, заболевания десен и повышенная слезоточивость глаз. Некоторые
представители бомбейской породы склонны к ожирению. Назначить правильное
лечение поможет врач ветеринар.</p>
                  `
                },

                {
                    title: ' Австралийская овчарка',
                    subInfo: 'SEO-опис породи',
                    content: `
                        <p><strong>Общие характеристики и родина породы</strong></p>
                        <p>Трудолюбивые, ответственные, умные и добрые — именно так можно описать
характер австралийской овчарки, или как её еще называют — аусси. Порода аусси
была выведена в середине XX века и использовалась скотоводами для помощи по
хозяйству. Сейчас австралийские овчарки выступают как в роли пастухов, так и в
качестве компаньона. Ведь не зря данная порода собак считается одной из самых
преданных и надежных.</p>
<p>Первое, на что вы обратите внимание, глядя на австралийскую овчарку — ее глаза.
Они выразительные, яркие, миндалевидные. Могут быть зелеными, желтыми, карими
и синими, а часто разных цветов, например один глаз зелёный, а второй голубой.
Шерсть прямая, средней длины, голубого, черного или мраморного окраса с
характерными белыми отметинами. Хвост купирован, длинной не более 10 см. Весит
питомец обычно 22-30 кг, а высота в холке составляет 46-58 см. Продолжительность
жизни аусси 13-15 лет.</p>
<p>Главной особенностью австралийской овчарки является ее высокий интеллект.
Собаки этой породы не могут сидеть без дела, им очень важно иметь свои
обязанности. Послушные и легко обучаемые аусси ласковы с детьми и могут быть
замечательными няньками или помощниками по дому — им необходимо постоянно
находится с хозяином и быть ему полезными.</p>
<p>Родина породы: США</p>
<p><strong>Питания и уход за породой</strong></p>
<p>К сожалению, собаки данной породы не могут похвастаться крепким здоровьем и
склонны к различным заболеванием. Поэтому правильно подобранное, полезное и
сбалансированное питание играет важную роль. Выбирайте высококачественные
корма супер-премиум или холистик класса, учитывая вес и возраст животного. Если
готовить самостоятельно, в рацион необходимо включить нежирное мясо индейки,
утки или ягненка. Отлично подойдут овсяная, гречневая или рисовая каши.
Австралийские овчарки с удовольствием будут есть фрукты и овощи, особенно тыкву,
кабачки, яблоки и морковку. Периодически аусси необходимо давать витамины,
положительно влияющие на опорно двигательный аппарат, так как у собак этой
породы часто возникают проблемы с суставами.</p>
<p>Шерсть австралийской овчарки нуждается в минимальном уходе, достаточно будет
раз в неделю расчесывать питомца щеткой с грубой щетиной. Купайте собаку только
тогда, когда это действительно необходимо.</p>
<p> Дрессировать аусси одно удовольствие. Они понятливы, и новую информацию
схватывают буквально «на лету», к тому же очень любят учится. Собаки будут
слушаться своего хозяина и стараться ему угодить, выполняя все команды. Для
полноценного развития питомца необходимо регулярно заниматься изучением новых
команд.</p>
<p><strong>Возможные болезни и необходимые превентивные меры.</strong></p>
<p>Австралийские овчарки очень восприимчивы к достаточно большому количеству
заболеваний, поэтому необходимо тщательно следить за здоровьем питомца.
Наиболее распространенные заболевания: катаракта, эпилепсия, глухота, аллергия,
онкология, демодекоз, различные заболевания глаз, дисплазия тазобедренного
сустава. При первых симптомах какого-либо из заболеваний у животного, обращайтесь
к врачу ветеринару.</p>
                    `,
                },
            ]
        },
        {
            categoryTitle: 'Ветеринарія',
            articles:[
                {
                    title:'Терапия',
                    subInfo:'Опис послуги',
                    content:`
                    <p>Терапевтическая помощь врача ветеринара заключается в диагностике и лечении животного консервативными методами, то есть без хирургического вмешательства.
Основная задача терапевта — провести осмотр питомца, поставить предварительный диагноз, назначить необходимые лабораторные и инструментальные исследования, а также медикаменты, направленные на лечение вашего четвероногого друга. 
Терапевты не только ведут прием больных животных, но и оказывают смежные услуги, например возможны вакцинация и чипирование на дому, антипаразитарная обработка, чистка ушей, гигиенические процедуры, консультирование в вопросах по уходу за питомцем.
</p>
<p><strong>В каких случаях стоит обратиться к ветеринару-терапевту?</strong></p>
<p>Если вы заметили у вашего любимца следующие симптомы, рекомендуем незамедлительно вызвать ветеринара на дом: </p>
<ul>
<li>Апатия и отсутствие аппетита;
</li>
<li>рвота и диарея продолжительностью более 4 часов;</li>
<li>наличие крови в кале, моче или рвотных массах;
</li>
<li>сонливость и дезориентация;</li>
<li>одышка, которая сопровождается синюшностью или побледнением слизистых;</li>
<li>нарушение терморегуляции (повышение или снижение температуры тела);</li>
<li>проблемы с мочеиспусканием;</li>
<li>позывы к рвоте непродуктивного характера;</li>
<li>вздутие живота и запор;</li>
<li>гнойные выделения из половых органов.</li>
</ul>
<p><strong>Как проходит терапевтический осмотр?</strong></p>
<p>В первичный осмотр питомца на дому входят:</p>
<p><b>Сбор анамнеза.</b> Врач узнает об особенностях питания и содержания животного, наличии хронических патологий, продолжительности симптоматики и пр. <br>
<b>Визуальный осмотр.</b> Оценка состояния органа чувств (зрение, слух, обоняние), кожи и шерсти, костно-мышечной системы. <br>
<b>Клинический осмотр</b>, включающий в себя:
<ul>
<li>измерение температуры с помощью специального инфракрасного термометра, помещенного на несколько секунд в ушную раковину или задний проход;</li>
<li>пальпацию, что позволяет оценить состояние лимфатических узлов, органов брюшной полости, мочевого пузыря, почек;</li>
<li>аускультацию для определения частоты и ритма сердцебиения и установки характера дыхательного шума.</li>
</ul> <br>
<b>Назначение лабораторной и инструментальной диагностики.</b>  В большинстве случаев, для постановки окончательного диагноза, требуется проведение дополнительных исследований. При необходимости, доктор проведет на дому УЗИ органов брюшной полости, мочеполовой системы, сердца, эндоскопическую диагностику, а также осуществит забор крови, кала, мочи, возьмет соскобы со слизистой оболочки, сделает тексты на наличие инфекционных патологий.
<b>Назначение консервативного лечения.</b> Завершающим этапом первичного осмотра является назначение необходимых медикаментов для дальнейшей терапии животного и детальная консультация касательно приема лекарственных средств.

</p>
<p>По получении результатов диагностических исследований, лечение корректируется. Также, опытный терапевт иногда направляет больного на дальнейшее дообследование к узкопрофильным специалистам (дерматологу, офтальмологу, хирургу).
</p>
<p>Здоровье питомца и спокойствие его владельцев для наших врачей — приоритетный фактор в их профессиональной деятельности. Наш клиент может рассчитывать на информационную поддержку в телефонном режиме на всех этапах лечения пациента.</p>

                    `,
                },
                {
                    title:'Хирургия',
                    subInfo:'Опис послуги',
                    content:`
                    <p>Бывают случаи, когда оказание эффективной помощи питомцу возможно только путем хирургического вмешательства. Поскольку операция — это всегда серьезно, она проводится после тщательного обследования животного (заключение терапевта, офтальмолога, дерматолога, проведение лабораторной и инструментальной диагностики). Исключение составляют лишь те пациенты, которые требуют экстренного вмешательства хирурга. Например, животные с кровотечением или разрывом внутренних органов, проникающими ранениями, серьезными гнойными воспалениями, тяжелыми травмами. В таких случаях, не оказанная вовремя помощь специалиста может стоить питомцу жизни.</p>
                    <p> 
Плановые операции осуществляются, как правило, по предварительной записи. За день до проведения процедуры, соблюдается специальная диета, иногда рекомендован прием седативных препаратов.
</p>
<p>К таким хирургическим вмешательствам относятся:</p>
<ul>
<li>стерилизация и кастрация;</li>
<li>удаление доброкачественных новообразований, параанальных желез;</li>
<li>устранение внешних дефектов, купирование ушей, хвоста;</li>
<li>лечение грыж, переломов, травм опорно-двигательного аппарата;</li>
<li>принятие родов (кесарево сечение);</li>
<li>оперативное лечение катаракты, панофтальмита.</li>
</ul>
<p>Как экстренная, так и плановая операция на дому осуществляется с использованием новейшего оборудования и современных препаратов, позволяющих не только обездвижить питомца, но и исключить любой дискомфорт во время проведения процедуры. Наши доктора используют одноразовые расходные материалы (салфетки, шприцы), все инструменты проходят тщательную дезинфекцию.</p>
<p>После проведения вмешательства до выхода из наркоза животное находится под наблюдением нашего специалиста. Опытный врач расскажет, как ухаживать за питомцем в послеоперационный период, и назначит вспомогательную терапию для быстрого восстановления. Мы гарантируем информационную поддержку в течение всего реабилитационного периода. Также клиент имеет возможность в любое время вызвать ветеринара на дом для проведения манипуляционных процедур (уколы, капельницы, снятие швов) или повторного осмотра.</p>

                    `,
                },
                {
                    title:'Стоматология',
                    subInfo:'Опис послуги',
                    content:`
                    <p>Ветеринары стоматологи оказывают широкий спектр услуг, направленных на устранение заболеваний ротовой полости. Врачи этого профиля занимаются профилактикой, диагностикой и лечением зубов и десен у животных. Ведь питомцы, как и люди, нуждаются в регулярном уходе за полостью рта. </p>
                    <p>Зачастую, именно болезни зубного ряда являются причиной проблем с печенью, почками, желудком. Во избежание развития серьезных патологий жизненно важных органов, опытные ветеринары рекомендуют минимум раз в год пользоваться услугами профильного специалиста. </p>
                    <p><b>Ветеринары-стоматологи осуществляют:</b></p>
                    <ul>
                    <li>профессиональную чистку зубов (механическую или ультразвуком);</li>
                    <li>санацию ротовой полости;</li>
                    <li>лечение при воспалении десен;</li>
                    <li>удаление зубов (молочных или коренных);</li>
                    <li>подрезание резцов у грызунов.</li>
                    </ul>
                    <p>Необходимые манипуляции наш ветеринар на дому проводит, используя современное оборудование и препараты. Как правило, стоматологические процедуры осуществляются с применением седации. Животным старше 7 лет, рекомендовано предварительное биохимическое исследование показателей работы почек.</p>
                    <p><strong>В каких случаях обращаться за ветеринарной помощью?</strong></p>
                    <p>Вызвать ветеринара на дом следует, если вы заметили у вашего любимца такие симптомы:</p>
                    <ul>
                    <li>Неприятный запах изо рта;</li>
                    <li>кровоточивость, изменение цвета десен;</li>
                    <li>язвы или припухлость слизистой оболочки ротовой полости;</li>
                    <li>обильное слюноотделение;</li>
                    <li>отказ от приема пищи;</li>
                    <li>дискомфорт при пережевывании еды.</li>
                    </ul>
                    <p>Перед проведением лечения, наши специалисты тщательно осмотрят животное и предложат оптимальный вариант устранения проблемы. По завершении сеанса, питомцу могут быть дополнительно назначены медикаменты (антисептики, антибиотики) и рекомендован повторный прием стоматолога на дому. </p>
                    
 
                    `,
                },
                {
                    title:'Вакцинация',
                    subInfo:'Опис послуги',
                    content:`
                    <p>Своевременная вакцинация является неотъемлемой частью правильного ухода за домашними животными. Прививки позволяют защитить питомца от инфицирования различными паразитами (кишечные палочки, бактерии, грибы) и серьезными вирусными заболеваниями (чумка, энтерит, гепатит, бешенство), нередко несущими угрозу жизни четвероногому любимцу.</p>
                    <p>Заражению подвержены даже те животные, которые не выходят на улицу. Так, часто, источник инфекции находится на сырых продуктах, нашей одежде или обуви. Переносчиками также могут являться пришедшие в дом гости, имеющие накануне контакт с больным питомцем.
</p>
                    <p>После введения необходимой вакцины, у животного формируется иммунитет к определенной группе заболеваний. Первую обязательную прививку щенкам и котятам делают в возрасте 8-12 недель, следующие — не ранее чем через 21 день после первой вакцинации. При этом, очень важно вести календарь инъекций. Ревакцинацию собакам и кошкам проводят ежегодно.</p>
                    <p><strong>Как подготовить питомца к вакцинации?</strong></p>
                    <p>Перед тем, как прививать животное, наш врач ветеринар на дому проведет комплексный осмотр вашего четвероногого друга, возьмет необходимые анализы, даст рекомендации по дегельминтизации и обработке против блох и клещей. На момент вакцинации, животное должно быть абсолютно здоровым. Запрещено вводить инъекции питомцам с тяжелыми хроническими патологиями, в период восстановления после хирургических вмешательств, самкам во время течки, лактации и беременности.</p>
                    <p>В первые дни после процедуры, введенный препарат стимулирует выработку антител к возбудителям инфекций, тем самым формируя мощный иммунитет. В течение 48 часов после проведения вакцинации у животного может наблюдаться небольшое повышение температуры тела, слабость и апатия, отсутствие аппетита. Такое состояние считается нормой. Если недомогание сохраняется более двух суток, обратитесь в нашу ветеринарную службу и вам будет предложена консультация ветеринара с вызовом на дом при необходимости.
</p>
                    `,
                },
                {
                    title:'Акушерство и гинекология',
                    subInfo:'Опис послуги',
                    content:`
                    <p>В период вынашивания потомства, животное особенно нуждается в повышенном внимании и максимальной заботе. Чтобы беременность протекала без осложнений, а роды прошли легко и быстро, очень важны плановые осмотры ветеринара. Во время приема, доктор внимательно осматривает будущую мамочку, проводит забор крови и мочи для дальнейшей лабораторной диагностики, назначает витамины и устанавливает приблизительную дату появление на свет малышей.</p>
                    <p>Определить, что роды начнутся с дня на день, владельцы питомица могут по внешним признакам:</p>
                    <ul>
                    <li>выделение молозива из молочных желез;</li>
                    <li>провисание живота и западение крестца;</li>
                    <li>прозрачные или кровянистые выделения из влагалища.</li>
                    </ul>
                    <p>
                    Если все симптомы «на лицо», но разродиться самостоятельно самка никак не может, требуется помощь специалиста. Ветеринар вводит животному препараты, способствующие повышению тонуса и сократимости миометрия. Далее осуществляется кесарево сечение животного на дому.
</p>
<p>Оперативное вмешательство зачастую необходимо и в таких случаях:</p>
<ul>
<li>продолжительность схваток — более 24 часов, при этом у питомицы наблюдаются пониженная температура тела, рвота, беспокойство или пугливость, из родовых путей выделяется слизь;</li>
<li>острые болевые ощущения при ощупывании живота или половых органов;</li>
<li>кровотечение из влагалища, зеленые или черные выделения.</li>
</ul>
<p>Даже если беременность протекает гладко, но ранее вы никогда не принимали роды у животного, рекомендуем вызвать ветеринара на дом. Ведь очень многие владельцы любимицы, сталкиваясь с таким интимным процессом в первый раз, теряются, начинают паниковать, чем могут значительно навредить самке.</p>
<p>При естественных родах, врач ветеринар на дому:</p>
<ul>
<li>извлечет плод;</li>
<li>обрежет пуповину новорожденным;</li>
<li>проведет необходимые процедуры для полного раскрытия легких и улучшения кровообращения у малышей.</li>
</ul>
<p>Независимо от того, было оказано оперативное вмешательство, или помощь в естественных родах, наш специалист осмотрит новоиспеченную мамочку и расскажет, как ухаживать за самкой и малышами во избежание осложнений.
</p>
                    `,
                },
            ]
        },
        {
            categoryTitle:'Статті у блог',
            articles:[
                {
                    title: 'О чем молчит ваш питомец?',
                    subInfo: 'Принимая решение завести собаку, мы должны понимать, что теперь её будущее полностью зависит от нас. И речь идет не только лишь о сбалансированном питании, регулярных прогулках и играх с питомцем, но и о своевременной профилактике и лечении различных заболеваний...',
                    content: `
                    <p>
                    Принимая решение завести собаку, мы должны понимать, что теперь её будущее
полностью зависит от нас. И речь идет не только лишь о сбалансированном питании,
регулярных прогулках и играх с питомцем, но и о своевременной профилактике и
лечении различных заболеваний. <br>
К сожалению, в отличие от человека, собака не может нам сказать, что у нее болит, да
и унаследованные от диких предков инстинкты не позволяют питомцу жаловаться на
боль. Как же определить, что беспокоит четвероногого друга? Несмотря на то, что
животные не могут рассказать нам о своем самочувствии, они подают нам знаки. Лишь
внимательное отношение и забота хозяина помогут распознать недуг на ранней
стадии и избежать серьезных последствий.
</p>
<p><strong>Собака ходит по кругу</strong></p>
<p>Хождение или бег питомца по кругу большинство хозяев воспринимают как игру и
необходимость собаке выплеснуть свою энергию. Действительно, для щенков это
может быть нормальным поведением и признаком того, что малышу недостаточно
ежедневного выгула. Если подобные действия происходят сразу после прогулки,
купания или возвращения хозяев домой, а болезненных симптомов не наблюдается,
скорее всего, переживать не стоит. <br>
Так называемые «манежные» движения взрослых собак без объективных на то
причин, могут свидетельствовать о наличии серьезных заболеваний. Среди них
проблемы неврологического характера, черепно-мозговые травмы, нарушения
мозгового кровообращения. Такие болезни приводят к серьезным последствиям и
требуют незамедлительного посещения ветеринара-невролога.
</p>
<p><strong>Собака трясет головой или чешет уши</strong></p>
<p>Самая распространенная причина такого симптома — воспаление уха. Если вы
заметили, что собака постоянно трясет головой и пытается почесать уши, следует
проверить нет ли у животного отита. Чаще всего им болеют собаки с висячими ушами
(лабрадоры, спаниели, бигли, курцхаары и пр.) При отодекозе (ушном клеще), питомец
будет бодаться и пытаться почесать пораженное ухо о различные предметы мебели, а
прикосновения к органу слуха вызовут у собаки острые болезненные ощущения.
Подобное поведение также наблюдается при наличии инородного тела в ухе. Не стоит
пытаться самостоятельно лечить животное. Поставить правильный диагноз и
подобрать оптимальную терапию поможет врач-ветеринар.</p>
<p>
<b>МЫ РЕКОМЕНДУЕМ</b> <br>
<i>Средства для ухода за ушами:</i> <br>
- Nutri-Vet Ear Cleanse ушные капли для собак <br>
- Canina Ohren Вalsam — бальзам для ухода за ушами собак и кошек <br>
- 8in1 Ear Clear
</p>
<p><strong>Собака нехотя поднимается на лапы</strong></p>
<p>
Если любимец с трудом поднимается на лапы и не спешит бежать, когда вы его
зовете, у него скорее всего присутствуют проблемы с лишним весом, часто
сопровождающиеся болезнями суставов. Не стоит доверять наивному взгляду
‎«‎попрошайки» и кормить последним кусочком какой-нибудь вкусняшки. Оптимальным
решением является подобрать правильный рацион, с учетом возраста и
физиологических особенностей собаки, а также придерживаться норм кормления,
установленных ветеринаром.
</p>
<p>
<b>МЫ РЕКОМЕНДУЕМ</b> <br>
<i>Низкокалорийная продукция для борьбы с лишним весом:</i> <br>
Royal Canin Maxi Light Weight Care <br>
Royal Canin Medium Light Weight Care<br>
Royal Canin Mini Light Weight Care<br>
Hill&#39;s SP Canine Adult Light<br>
Acana Heritage Light &amp; Fit 35/11<br>
Brit Care Weight Loss Rabbit<br>
Arden Grange Adult Dog Light<br>
1st Choice Light Healthy Weight<br>
</p>
<p><strong>Сухой и теплый нос</strong></p>
<p>Наверняка, почти каждый хозяин слышал, что сухой и теплый нос у питомца признак
повышенной температуры и простуды. Однако сразу брать термометр и лишний раз
тревожить животное не стоит. Нос теплым может быть к примеру после крепкого сна,
при жаре или сильном морозе — такое состояние является нормальным. Но при
наличии комплекса симптомов, таких как ухудшение аппетита, диарея, чихание, храп,
сухой и теплый нос может служить одним из признаков недомогания.</p>
<p>
<b>МЫ РЕКОМЕНДУЕМ</b> <br>
<i>Витамины для укрепления иммунной системы:</i> <br>

8in1 Brewers Yeast<br>
Canina Hefe Yeast - дрожжевые таблетки с энзимами и ферментами для собак<br>
Canina Dog Immun Protect - иммунитет и здоровый кишечник<br>
Canina V25 - витаминный комплекс для собак<br>
</p>
<p><strong>Обильное слюноотделение</strong></p>
<p>
Обильное слюноотделение у собаки — очень тревожный знак. Причины такого
симптома — заболевания центральной нервной системы, язвы в желудке и ротовой
полости, различные опухоли, эпилепсия, инородные предметы в области верхних
дыхательных путей, инфекционные заболевания, в частности бешенство. Кроме того
повышенное слюноотделение иногда является следствием сильного стресса. Точный
диагноз сможет поставить только ветеринар, к которому стоит незамедлительно
обратиться.
</p>
<p><strong>Увеличенный в объеме живот</strong></p>
<p>
Увеличенный в объеме живот, который сопровождается медленной походкой
вперевалку, чаще всего следствие лишнего веса у питомца. У щенков подобная форма
живота свидетельствует о наличии гельминтов и требует незамедлительного лечения
против паразитов. Вздутие живота может также быть вызвано нерегулярным выгулом
животного, накоплением жидкости в результате болезней печени или при почечной
недостаточности, воспалением матки у самок. У пожилых собак такой симптом
возникает при асците и водянке. Если вы заметили резкое увеличение объема живота,

затрудненное дыхание, рвотные позывы и общую слабость у питомца, во избежание
серьезных последствий, в кратчайшие сроки обратитесь в ветеринарную клинику.
</p>
<p><strong>Собака хромает или ложится на землю при прогулке</strong></p>
<p>Если ваш любимец во время физических нагрузок и прогулок не такой активный как
раньше, принимает вынужденные позы или хромает, возможно у него возникли
проблемы с опорно-двигательным аппаратом. Показателем заболеваний костей и
суставов является также хромота собаки сразу после сна. Когда во время дрессировки
питомец вас не слушается и скорее предпочтет полежать, не стоит его наказывать.
Обратитесь к ветеринарному доктору для определения правильного диагноза и
назначения соответствующего лечения.</p>
<p>
<b>МЫ РЕКОМЕНДУЕМ:</b> <br>
<i>Препараты для профилактики и лечения заболеваний опорно-двигательного
аппарата:</i> <br>

Canina Calcina Calcium Citrat<br>
Canvit Chondro Super препарат с глюкозамином, хондроитином и МСМ для собак<br>
Canina Canhydrox GAG Forte<br>
8in1 Excel Mobile Flex Plus<br>
Canvit Chondro - для поддержания здоровья связок и суставов у собак<br>
</p>

                    `,
                },
                {
                    title: 'Как определить возраст собаки?',
                    subInfo: 'Каждый хозяин должен знать возраст своего пушистого друга. Если вы решили приобрести породистого любимца у заводчиков, то обязательно получите документ щенка: ветеринарный паспорт, где обычно указана информация о прививках, а также дата рождения собаки. Однако судьбоносная встреча может произойти где угодно...',
                    content: `
                    <p>Каждый хозяин должен знать возраст своего пушистого друга. Если вы решили
приобрести породистого любимца у заводчиков, то обязательно получите
документ щенка: ветеринарный паспорт, где обычно указана информация о
прививках, а также дата рождения собаки. Однако судьбоносная встреча может
произойти где угодно. В случае, если животное было найдено на улице или
куплено с рук на Птичьем рынке и данные о возрасте отсутствуют,
новоиспеченному владельцу питомца придется самостоятельно оценивать его
возраст.</p>
<p><strong>Зачем нужно знать возраст собаки?</strong></p>
<p>
Знать возраст питомца очень важно. В первую очередь это нужно для
своевременной вакцинации животного. Молодых собак необходимо прививать
ежегодно. Если же питомец старше 7 лет, вакцинация проводится раз в 2-3 года.
Возраст также важен и для расчета правильной дозировки медикаментов в период
лечения четвероногого друга или во время проведения хирургических
вмешательств. Если вы решили стерилизовать животное, ветеринары
рекомендуют проводить операцию примерно в 6-7 месяцев. Так можно
предотвратить проблемы со здоровьем у собаки— возникновение онкологических
заболеваний, половых инфекций, выпадения матки и другие недуги. А вот ранняя
стерилизация, до 6 месяцев, наоборот может нанести вред растущему организму.
В зависимости от возраста собаки меняется также график прогулок, количество
физических нагрузок и конечно же рацион. Очень важно правильно подобрать
сухой корм для собак или заниматься приготовлением пищи самостоятельно,
учитывая возраст животного, его активность и физиологические потребности.
</p>
<p><strong>Определяем возраст по зубам</strong></p>
<p>Наиболее точным показателем возраста любимца является состояние ее зубного
ряда. У собак, также как и у людей, зубы меняются единожды. Если питомец жил в
хороших условиях и имеет правильный прикус, определить сколько лет животному
можно максимально точно. Первые молочные зубки у щенка появляются через 20-
35 дней после рождения, по размеру они меньше постоянных и похожи на
небольшие острые иголочки. В период 4-6 месяцев молочные резцы постепенно
заменяются постоянными, а к 8 месяцам, как правило, зубной ряд меняется
полностью Годовалый пес отличается белоснежной улыбкой, острые резцы
напоминают форму трилистника. В возрасте 1,5-3 лет происходит стирание
выступающей части на нижних резцах, безупречно белая эмаль постепенно
приобретает оттенок слоновой кости. К 4-5 годам верхняя часть резцов полностью
теряется, снижается острота клыков, а в 6 лет зубы заметно желтеют В период 7-9
лет стирание резцов ярко выражено, клыки тупеют, помимо характерной желтизны
появляется налет, может присутствовать зубной камень. У пожилых питомцев

старше 10 лет зубы часто напоминают короткие тупые пеньки, начинают шататься
и нередко могут выпадать. Также может присутствовать кариес, который является
самым главным признаком преклонного возраста собаки.</p>
<p><strong>Глаза и шерсть подскажут сколько лет питомцу</strong></p>
<p>
Кроме состояния зубного ряда, количество прожитых лет любимца можно также
определить, обратив внимание на его внешний вид и особенности поведения.
Молодые собаки отличаются хорошо развитой мускулатурой, высокой
активностью, ясным, задорным взглядом. Мягкая, шелковистая шерсть меньше
подвержена загрязнениям. С годами «‎шубка» теряет свой насыщенный цвет, на
ощупь становится маслянистой. С 6-7 лет на мордочке у питомца может
наблюдаться первая седина, которая со временем появляется на лапах и животе.
Роговица глаза мутнеет, иногда бывает слезоточивость.Мышечный тонус заметно
снижается, спина может провисать, а живот опускается вниз. Активным играм,
пожилые питомцы скорее предпочтут спокойный отдых. Они быстрее устают и
больше спят. «‎Старичкам» часто характерна хромота в походке, появляется
скованность движений, суставы теряют былую гибкость.
</p>
<p>Оценивая примерный возраст любимого друга, следует рассматривать сразу
несколько признаков. С наибольшей точностью специалисты как правило
определяют дату рождения щенка или молодой собаки. Внешний вид пожилых
питомцев не столько зависит от количества прожитых лет, сколько от образа жизни
и условий содержания животного. Очень часто питомцы в преклонном возрасте
наслаждаются долгими годами полноценной жизни, оставаясь активными и
здоровыми.</p>
                    `
                },
                {
                    title:'Осторожно: Новый год!',
                    subInfo:'Новый год — это долгожданный, веселый и любимый всеми праздник, однако во время предновогодней суеты, наряжая елку и накрывая новогодний стол, важно не забывать о наших любимцах. Именно во время новогодних праздников, ветеринарные клиники принимают большое количество домашних животных, которые нуждаются в помощи из-за невнимательности своих хозяев...',
                    content:`
                    <p>
                    Новый год — это долгожданный, веселый и любимый всеми праздник, однако во
время предновогодней суеты, наряжая елку и накрывая новогодний стол, важно не
забывать о наших любимцах. Именно во время новогодних праздников, ветеринарные
клиники принимают большое количество домашних животных, которые нуждаются в
помощи из-за невнимательности своих хозяев. Чтобы наступающие праздники стали
безопасными для вашего четвероногого друга, при подготовке к веселому
мероприятию, следует помнить о некоторых факторах, которые могут навредить
здоровью питомца.
</p>
<p><strong>Елочная мишура</strong></p>
<p>Красивые яркие шарики, блестящий дождик, мигающие лампочки и конечно же
большая, пушистая ель — всё это гарантированно привлечет внимание домашнего
любимца. К сожалению новогодняя атрибутика таит в себе немало опасностей. Во-первых, 
с появлением нового экзотического дерева в доме, животному непременно
понадобится проверить его на прочность, особо гибкие питомцы могут оказаться на
верхушке новогодней елки и в конце-концов опрокинуть ее. Для некоторых это
покажется забавным, однако большое, увесистое дерево может травмировать
питомца, а разбившиеся елочные игрушки поранить его. Во избежание подобных
травм, выбирайте небольшую, настольную ель, а в качестве украшений, подберите
пластмассовые игрушки.</p>
<p>Также стоит отказаться от любимого «лакомства» кошек во время праздников —
новогоднего дождика. Проглотив часть такой игрушки, животное уже не в состоянии
выплюнуть ее самостоятельно, и как правило, ест целиком. При удачном стечении
обстоятельств, украшение выходит естественным путем. Однако острая фольга, из
которой изготовлен дождик, при проглатывании вашей любимицей, может нанести ее
внутренним органам очень серьезные повреждения. В идеале стоит вовсе отказаться
от опасной мишуры. Однако, если вы заметили, что ваш пушистый друг все же съел
ворсистую блестящую ленту и у него наблюдаются такие симптомы как запор, позывы
к рвоте, слабость и беспокойство — следует незамедлительно обратиться в
ветеринарную клинику.</p>
<p><strong>Праздничный стол и щедрые гости</strong></p>
<p>Обильно накрытый праздничный стол — второе испытание для организма домашних
питомцев. Когда 31-го декабря в доме пахнет вкусной курочкой и во всю готовится
традиционный салат Оливье, пушистым проказникам так и хочется стянуть со стола
что-нибудь вкусненькое. Ведь, как известно, нюх собак и кошек намного острее
обоняния человека. Ароматы приготовлений к праздничному столу будут
провоцировать аппетит у любимца. Но то, что для нас очень вкусно — яд для
животных. Жирная и острая пища, майонез, сладости, мандарины, а тем более
алкогольные напитки категорически не приемлемы к употреблению питомцами. Такие
«лакомства» могут вызвать пищевое отравление, приступ острого панкреатита, а
иногда и заворот кишок. Во избежание расстройства желудка у вашего питомца,
старайтесь не оставлять накрытый стол без присмотра, а также предупредите гостей о
последствиях подкармливания животных со стола. Лучше всего заранее продумать
праздничный рацион четвероногого друга. Сейчас предлагается масса различных
вкусняшек для собак и лакомств для кошек: молочные дропсы, нежные паштеты и
даже специальный шоколад. Можно поставить угощение возле новогоднего стола, и
если гости не смогут устоять перед грустным взглядом маленького попрошайки, у них
будет возможность покормить животное без последствий для его здоровья.</p>
<p><strong>Зверушка в подарок</strong></p>
<p>Если вы решили презентовать в качестве новогоднего подарка животное, обязательно
посоветуйтесь с его будущим хозяином заранее. Дарить щенка, котенка, морскую
свинку или хомячка друзьям или родственникам стоит только в том случае, если они
ждут от вас подобный сюрприз. Также, лучше заранее обсудить с одариваемым
породу, окрас, пол и возраст желанной зверушки.</p>
<p><strong>Новогодняя феерия</strong></p>
<p>Внимательные хозяева отлично знают, как питомцы реагируют на громкие звуки. Когда
собака поджимает хвост услышав шумный пылесос, а кошка убегает в дальний угол
комнаты, прячась от звуков фена. Взрывы хлопушек, петард и фейерверков станут для
любимца самым настоящим стрессом. С конца декабря до середины января собаку
стоит выводить на прогулку на поводке, оберегая ее от нетрезвых прохожих и
скучающих на каникулах школьников. Если в разгар новогодней ночи вы решили
продолжить празднование на улице, питомца следует оставить дома. Именно во
время новогодних праздников теряется наибольшее количество собак. Даже у самого
послушного питомца может быть неожиданная реакция на шум и взрывы. Напуганное
животное способно убежать достаточно далеко от дома, что весьма затруднит его
поиски.</p>
<p><strong>Порадуйте пушистого друга :)</strong></p>
<p>Новый год — праздник для всех. Команды, выученные в этом году вашей собакой,
послушание кошки, парочка новых слов от попугая — отличный повод для того, чтобы
сделать приятное четвероногому члену семьи в канун праздников. Подбирая подарки
для родных и близких, не забудьте порадовать собаку новой игрушкой или заранее
подготовить любимый деликатес для пушистой мурлыки. Ведь домашние питомцы
проявляют к нам свою любовь, внимание и преданность независимо от обстоятельств
в жизни и дня в календаре.</p>
<p><strong>С наступающим Новым годом!</strong></p>
                    `,
                },
            ],
        }
    ],



    vet:[
        {
            categoryTitle:'',
            articles:[
                {
                    title:'Терапия',
                    subInfo:'Опис послуги',
                    content:`
                    <p>Терапевтическая помощь врача ветеринара заключается в диагностике и лечении животного консервативными методами, то есть без хирургического вмешательства.
Основная задача терапевта — провести осмотр питомца, поставить предварительный диагноз, назначить необходимые лабораторные и инструментальные исследования, а также медикаменты, направленные на лечение вашего четвероногого друга. 
Терапевты не только ведут прием больных животных, но и оказывают смежные услуги, например возможны вакцинация и чипирование на дому, антипаразитарная обработка, чистка ушей, гигиенические процедуры, консультирование в вопросах по уходу за питомцем.
</p>
<p><strong>В каких случаях стоит обратиться к ветеринару-терапевту?</strong></p>
<p>Если вы заметили у вашего любимца следующие симптомы, рекомендуем незамедлительно вызвать ветеринара на дом: </p>
<ul>
<li>Апатия и отсутствие аппетита;
</li>
<li>рвота и диарея продолжительностью более 4 часов;</li>
<li>наличие крови в кале, моче или рвотных массах;
</li>
<li>сонливость и дезориентация;</li>
<li>одышка, которая сопровождается синюшностью или побледнением слизистых;</li>
<li>нарушение терморегуляции (повышение или снижение температуры тела);</li>
<li>проблемы с мочеиспусканием;</li>
<li>позывы к рвоте непродуктивного характера;</li>
<li>вздутие живота и запор;</li>
<li>гнойные выделения из половых органов.</li>
</ul>
<p><strong>Как проходит терапевтический осмотр?</strong></p>
<p>В первичный осмотр питомца на дому входят:</p>
<p><b>Сбор анамнеза.</b> Врач узнает об особенностях питания и содержания животного, наличии хронических патологий, продолжительности симптоматики и пр. <br>
<b>Визуальный осмотр.</b> Оценка состояния органа чувств (зрение, слух, обоняние), кожи и шерсти, костно-мышечной системы. <br>
<b>Клинический осмотр</b>, включающий в себя:
<ul>
<li>измерение температуры с помощью специального инфракрасного термометра, помещенного на несколько секунд в ушную раковину или задний проход;</li>
<li>пальпацию, что позволяет оценить состояние лимфатических узлов, органов брюшной полости, мочевого пузыря, почек;</li>
<li>аускультацию для определения частоты и ритма сердцебиения и установки характера дыхательного шума.</li>
</ul> <br>
<b>Назначение лабораторной и инструментальной диагностики.</b>  В большинстве случаев, для постановки окончательного диагноза, требуется проведение дополнительных исследований. При необходимости, доктор проведет на дому УЗИ органов брюшной полости, мочеполовой системы, сердца, эндоскопическую диагностику, а также осуществит забор крови, кала, мочи, возьмет соскобы со слизистой оболочки, сделает тексты на наличие инфекционных патологий.
<b>Назначение консервативного лечения.</b> Завершающим этапом первичного осмотра является назначение необходимых медикаментов для дальнейшей терапии животного и детальная консультация касательно приема лекарственных средств.

</p>
<p>По получении результатов диагностических исследований, лечение корректируется. Также, опытный терапевт иногда направляет больного на дальнейшее дообследование к узкопрофильным специалистам (дерматологу, офтальмологу, хирургу).
</p>
<p>Здоровье питомца и спокойствие его владельцев для наших врачей — приоритетный фактор в их профессиональной деятельности. Наш клиент может рассчитывать на информационную поддержку в телефонном режиме на всех этапах лечения пациента.</p>

                    `,
                },
                {
                    title:'Хирургия',
                    subInfo:'Опис послуги',
                    content:`
                    <p>Бывают случаи, когда оказание эффективной помощи питомцу возможно только путем хирургического вмешательства. Поскольку операция — это всегда серьезно, она проводится после тщательного обследования животного (заключение терапевта, офтальмолога, дерматолога, проведение лабораторной и инструментальной диагностики). Исключение составляют лишь те пациенты, которые требуют экстренного вмешательства хирурга. Например, животные с кровотечением или разрывом внутренних органов, проникающими ранениями, серьезными гнойными воспалениями, тяжелыми травмами. В таких случаях, не оказанная вовремя помощь специалиста может стоить питомцу жизни.</p>
                    <p> 
Плановые операции осуществляются, как правило, по предварительной записи. За день до проведения процедуры, соблюдается специальная диета, иногда рекомендован прием седативных препаратов.
</p>
<p>К таким хирургическим вмешательствам относятся:</p>
<ul>
<li>стерилизация и кастрация;</li>
<li>удаление доброкачественных новообразований, параанальных желез;</li>
<li>устранение внешних дефектов, купирование ушей, хвоста;</li>
<li>лечение грыж, переломов, травм опорно-двигательного аппарата;</li>
<li>принятие родов (кесарево сечение);</li>
<li>оперативное лечение катаракты, панофтальмита.</li>
</ul>
<p>Как экстренная, так и плановая операция на дому осуществляется с использованием новейшего оборудования и современных препаратов, позволяющих не только обездвижить питомца, но и исключить любой дискомфорт во время проведения процедуры. Наши доктора используют одноразовые расходные материалы (салфетки, шприцы), все инструменты проходят тщательную дезинфекцию.</p>
<p>После проведения вмешательства до выхода из наркоза животное находится под наблюдением нашего специалиста. Опытный врач расскажет, как ухаживать за питомцем в послеоперационный период, и назначит вспомогательную терапию для быстрого восстановления. Мы гарантируем информационную поддержку в течение всего реабилитационного периода. Также клиент имеет возможность в любое время вызвать ветеринара на дом для проведения манипуляционных процедур (уколы, капельницы, снятие швов) или повторного осмотра.</p>

                    `,
                },
                {
                    title:'Стоматология',
                    subInfo:'Опис послуги',
                    content:`
                    <p>Ветеринары стоматологи оказывают широкий спектр услуг, направленных на устранение заболеваний ротовой полости. Врачи этого профиля занимаются профилактикой, диагностикой и лечением зубов и десен у животных. Ведь питомцы, как и люди, нуждаются в регулярном уходе за полостью рта. </p>
                    <p>Зачастую, именно болезни зубного ряда являются причиной проблем с печенью, почками, желудком. Во избежание развития серьезных патологий жизненно важных органов, опытные ветеринары рекомендуют минимум раз в год пользоваться услугами профильного специалиста. </p>
                    <p><b>Ветеринары-стоматологи осуществляют:</b></p>
                    <ul>
                    <li>профессиональную чистку зубов (механическую или ультразвуком);</li>
                    <li>санацию ротовой полости;</li>
                    <li>лечение при воспалении десен;</li>
                    <li>удаление зубов (молочных или коренных);</li>
                    <li>подрезание резцов у грызунов.</li>
                    </ul>
                    <p>Необходимые манипуляции наш ветеринар на дому проводит, используя современное оборудование и препараты. Как правило, стоматологические процедуры осуществляются с применением седации. Животным старше 7 лет, рекомендовано предварительное биохимическое исследование показателей работы почек.</p>
                    <p><strong>В каких случаях обращаться за ветеринарной помощью?</strong></p>
                    <p>Вызвать ветеринара на дом следует, если вы заметили у вашего любимца такие симптомы:</p>
                    <ul>
                    <li>Неприятный запах изо рта;</li>
                    <li>кровоточивость, изменение цвета десен;</li>
                    <li>язвы или припухлость слизистой оболочки ротовой полости;</li>
                    <li>обильное слюноотделение;</li>
                    <li>отказ от приема пищи;</li>
                    <li>дискомфорт при пережевывании еды.</li>
                    </ul>
                    <p>Перед проведением лечения, наши специалисты тщательно осмотрят животное и предложат оптимальный вариант устранения проблемы. По завершении сеанса, питомцу могут быть дополнительно назначены медикаменты (антисептики, антибиотики) и рекомендован повторный прием стоматолога на дому. </p>
                    
 
                    `,
                },
                {
                    title:'Вакцинация',
                    subInfo:'Опис послуги',
                    content:`
                    <p>Своевременная вакцинация является неотъемлемой частью правильного ухода за домашними животными. Прививки позволяют защитить питомца от инфицирования различными паразитами (кишечные палочки, бактерии, грибы) и серьезными вирусными заболеваниями (чумка, энтерит, гепатит, бешенство), нередко несущими угрозу жизни четвероногому любимцу.</p>
                    <p>Заражению подвержены даже те животные, которые не выходят на улицу. Так, часто, источник инфекции находится на сырых продуктах, нашей одежде или обуви. Переносчиками также могут являться пришедшие в дом гости, имеющие накануне контакт с больным питомцем.
</p>
                    <p>После введения необходимой вакцины, у животного формируется иммунитет к определенной группе заболеваний. Первую обязательную прививку щенкам и котятам делают в возрасте 8-12 недель, следующие — не ранее чем через 21 день после первой вакцинации. При этом, очень важно вести календарь инъекций. Ревакцинацию собакам и кошкам проводят ежегодно.</p>
                    <p><strong>Как подготовить питомца к вакцинации?</strong></p>
                    <p>Перед тем, как прививать животное, наш врач ветеринар на дому проведет комплексный осмотр вашего четвероногого друга, возьмет необходимые анализы, даст рекомендации по дегельминтизации и обработке против блох и клещей. На момент вакцинации, животное должно быть абсолютно здоровым. Запрещено вводить инъекции питомцам с тяжелыми хроническими патологиями, в период восстановления после хирургических вмешательств, самкам во время течки, лактации и беременности.</p>
                    <p>В первые дни после процедуры, введенный препарат стимулирует выработку антител к возбудителям инфекций, тем самым формируя мощный иммунитет. В течение 48 часов после проведения вакцинации у животного может наблюдаться небольшое повышение температуры тела, слабость и апатия, отсутствие аппетита. Такое состояние считается нормой. Если недомогание сохраняется более двух суток, обратитесь в нашу ветеринарную службу и вам будет предложена консультация ветеринара с вызовом на дом при необходимости.
</p>
                    `,
                },
                {
                    title:'Акушерство и гинекология',
                    subInfo:'Опис послуги',
                    content:`
                    <p>В период вынашивания потомства, животное особенно нуждается в повышенном внимании и максимальной заботе. Чтобы беременность протекала без осложнений, а роды прошли легко и быстро, очень важны плановые осмотры ветеринара. Во время приема, доктор внимательно осматривает будущую мамочку, проводит забор крови и мочи для дальнейшей лабораторной диагностики, назначает витамины и устанавливает приблизительную дату появление на свет малышей.</p>
                    <p>Определить, что роды начнутся с дня на день, владельцы питомица могут по внешним признакам:</p>
                    <ul>
                    <li>выделение молозива из молочных желез;</li>
                    <li>провисание живота и западение крестца;</li>
                    <li>прозрачные или кровянистые выделения из влагалища.</li>
                    </ul>
                    <p>
                    Если все симптомы «на лицо», но разродиться самостоятельно самка никак не может, требуется помощь специалиста. Ветеринар вводит животному препараты, способствующие повышению тонуса и сократимости миометрия. Далее осуществляется кесарево сечение животного на дому.
</p>
<p>Оперативное вмешательство зачастую необходимо и в таких случаях:</p>
<ul>
<li>продолжительность схваток — более 24 часов, при этом у питомицы наблюдаются пониженная температура тела, рвота, беспокойство или пугливость, из родовых путей выделяется слизь;</li>
<li>острые болевые ощущения при ощупывании живота или половых органов;</li>
<li>кровотечение из влагалища, зеленые или черные выделения.</li>
</ul>
<p>Даже если беременность протекает гладко, но ранее вы никогда не принимали роды у животного, рекомендуем вызвать ветеринара на дом. Ведь очень многие владельцы любимицы, сталкиваясь с таким интимным процессом в первый раз, теряются, начинают паниковать, чем могут значительно навредить самке.</p>
<p>При естественных родах, врач ветеринар на дому:</p>
<ul>
<li>извлечет плод;</li>
<li>обрежет пуповину новорожденным;</li>
<li>проведет необходимые процедуры для полного раскрытия легких и улучшения кровообращения у малышей.</li>
</ul>
<p>Независимо от того, было оказано оперативное вмешательство, или помощь в естественных родах, наш специалист осмотрит новоиспеченную мамочку и расскажет, как ухаживать за самкой и малышами во избежание осложнений.
</p>
                    `,
                },
            ]
        }
    ],

    info_tech:[
        {
            categoryTitle:'',
            articles:[
                {
                    title: 'Розробка унікального дизайну під ключ',
                    subInfo: 'Опис послуги',
                    content: `
                    <p>
                   Web-дизайн сайту — це те, що в першу чергу бачить користувач, який зацікавився вашим продуктом. Саме візуальне оформлення відіграє вирішальну роль в утриманні потенційного клієнта для виконання подальших дій — перегляду товарів та послуг, оформлення замовлення, запиту на дзвінок. 
Дизайн чітко позиціонує ваш бренд, створює імідж та дозволяє з перших секунд оцінити з-поміж конкурентів.
 
</p>
<p><b>Переваги індивідуального веб-дизайну:</b></p>
<ul>
<li>персоналізація та унікальний впізнаваний стиль бренду;</li>
<li>відповідність потребам та особливостям вашої цільової аудиторії;</li>
<li>можливість впровадження цікавих рішень в подальшому, без прив’язки до шаблону.</li>
</ul>
<p>
Разом з тим, для власників малого бізнесу, в яких основним каналом залучення покупців є соцмережі або офлайн-реклама, часто достатньо придбати шаблон та адаптувати його під свій сайт. Цей варіант швидкий та бюджетний, але знижує унікальність ресурсу, тому підійде лише для роботи з «гарячими» клієнтами, що вже мають потребу в ваших товарах чи послугах — наприклад для більш детального ознайомлення з пропозицією.
</p>
<p><b>В свою чергу, розробка ексклюзивного веб-дизайну потребує детального опрацювання та складається з наступних етапів:</b></p>
<ul>
<li><b>Брифінг замовника, дослідження та аналіз його бізнесу. </b> Вивчення бренду, його основних конкурентів, виявлення сильних та слабких сторін компанії, формування ЦА.</li>
<li><b>Розробка прототипу.</b> Визначення з типом та структурою сторінок, відтворення смислових блоків та розміщення основних елементів.</li>
<li><b>Вибір та затвердження стилю.</b>Згідно з тематичним та бізнес-напрямком, визначається стиль (ар-деко, мінімалізм, органік та інші).</li>
<li><b>Візуальне оформлення.</b>Підбір кольорового рішення, шрифтів, іконок, графічних елементів, ілюстрацій та ін. Детальне опрацювання макетів сторінок по прототипам.</li>
<li><b>Створення адаптованої версії дизайну</b>для коректного відображення сайту на екранах із різним розміром.</li>
<li><b>Підготовка до верстки.</b>Складання відповідної документації або презентація дизайну front end розробнику з коментарями щодо вірного розміщення всіх елементів.</li>
</ul>
<p>
Кожен проміжний етап демонструється замовнику, вносяться відповідні побажання та корегування.
</p>
<p><strong>Сформуйте унікальний імідж свого бренду в ІТ Сity</strong></p>
<p>При створенні візуального оформлення веб-ресурсу ми слідуємо наступним принципам:</p>
<ul>
<li><b>Індивідуальність. </b>На основі брифінгу та аналізу, ми шукаємо саме «вашу фішку» та пропонуємо ексклюзивну дизайнерську концепцію, у відповідності з напрямком бізнесу та ЦА.</li>
<li><b>Легкість та простота.</b>Якщо від дизайну залежить те, чи користувач залишиться на сайті для вивчення асортименту, то від його адаптивності — подальші дії потенційного клієнта. Команда IT City вміє розробляти універсальний продукт, однаково зручний у використанні на різних девайсах.</li>
<li><b>Прозорість.</b>Ми надаємо безкоштовну консультацію, з’ясовуємо деталі і одразу обговорюємо терміни виконання та розраховуємо вартість послуг. Всі цифри ми підтверджуємо підписанням договору, який гарантує дотримання умов.</li>
<li><b>Підримка.</b>У разі необхідності, супроводжуємо проект після його запуску та проводимо технічні консультації.</li>
<li><b>Комплексний підхід.</b>Наша команда складається із спеціалістів різного профілю. Клієнт може замовити веб-дизайн, верстку, наповнення контентом та таким чином значно оптимізувати свій час на пошук інших фахівців та роз’яснення всіх деталей. Адже ми вже знаємо краще рішення для ефективного впровадження ваших бізнес-ідей.</li>
</ul>

                    
                    `
                },
                {
                    title: 'Створення CRM-системи',
                    subInfo: 'Опис послуги',
                    content: `
                    <p>
                   CRM-система — незамінний помічник для зручного та ефективного ведення бізнесу. Її основне призначення — оптимізація процесів взаємодії з потенційними та поточними клієнтами, зберігання великого обсягу інформації в одному місці, управління маркетинговими кампаніями, налагодження співпраці між різними підрозділами (наприклад, колл-центру, відділів логістики, продажів, навчання та ін.). Причому, незалежно від того, який основний напрямок компанії — роздрібна онлайн-торгівля, дистриб’юторство, телекомунікації, агенція нерухомості, мережа beauty-салонів чи інший — CRM стане корисною для бізнесу будь-якої галузі та розміру. 
</p>
<p><strong>Які задачі вирішує CRM-система?</strong></p>
<ul>
<li><b>Підвищення лояльності клієнтів.</b>Інформація про покупців — цінний актив, а грамотне управління даними дозволяє використовувати її максимально ефективно. ЦРМ зберігає багаторічну історію звернень, уподобань та тригерів відвідувачів ресурсу (наприклад незавершена угода), має функцію нотаток в картках «проблемних» клієнтів. Це дозволяє менеджерам аналізувати затребуваність товарів чи послуг бізнесу, вчасно робити актуальні пропозиції та надавати персоналізований сервіс.</li>
<li><b>Автоматизація маркетингу.</b>В CRM-системі можна керувати рекламним бюджетом, координувати роботу співробітників відділу, зберігати всі важливі маркетингові матеріали (такі, як результати опитувань, досліджень), сегментувати ЦА та налаштовувати електронні розсилки в залежності від потреб покупців. Також, в кастомізованих CRM є можливість підключення інструментів для аналізу.
</li>
<li><b>Планування, відстеження та автоматичне закриття завдань.</b>«Людський фактор» ніхто не відміняв. Часто, термін виконання багатьох задач відкладається, особливо коли для їх закриття потрібна взаємодія кількох відділів. В ЦРМ легко відстежити та визначити, де, що і коли пішло не так, і запобігти повторенню помилок. Крім того, система дозволяє налаштувати вирішення деяких рутинних завдань — наприклад автоматичну відправку щомісячних звітів.</li>
<li><b>Зростання продуктивності.</b>За рахунок того, що за допомогою якісної ЦРМ можна швидко закрити багато «ручних» процесів, зникає необхідність використання багатьох інших інструментів. Таких як планери, Excel таблиці, корпоративні месенджери, файлообмінники, і тим більше паперові документації. Це допомагає заощадити і час співробітників, і кошти компанії.</li>
<li><b>Мобільність.</b>Доступ до всіх необхідних даних можна отримати в одному місці, «залогінившись» з ноутбука, стаціонарного ПК чи смартфона, без прив’язки до робочого місця. Це є вагомим плюсом для компаній, що працюють у віддаленому режимі.</li>
</ul>
<p>
Разом з тим, існують деякі обмеження функціоналу, в залежності від типу CRM-системи.
<b>Вона може бути:</b>
</p>
<p>
- <b>Стандартизованою </b>— вже готове комерційне рішення, оптимізоване під основні напрямки бізнесу, з обмеженим списком опцій та без можливості індивідуальних налаштувань та розширень. Як правило, за використання такого ПО стягується щомісячна абонентська плата з додатковим відсотком за кожного нового співробітника. Серед переваг, відносно низька вартість підтримки та швидкий запуск.
<br>
- <b>Кастомізованою  </b>  — індивідуально розроблена система з урахуванням специфіки конкретного бізнесу та логіки його внутрішніх процесів. Функціонал можна доопрацьовувати та оновлювати за необхідності. 
</p>
<p>
Другий варіант вимагає більше часу та вкладень на старті, але швидко окупається, так як повністю закриває потреби замовника — від інтеграції з актуальними сервісами (месенджери, 1С, IP-телефонія), до можливості надавати доступ будь-якій кількості нових працівників без додаткових витрат. Ви сплачуєте лише за розробку на початку, та розширення опцій в майбутньому, якщо це необхідно бізнесу.
</p>
<p><strong>Створення CRM-системи в IT City</strong></p>
<p>
Для індивідуальної розробки CRM-системи залучається ціла команда спеціалістів: UI/UX дизайнер, front-end та back-end-розробники, тестувальник. Саме тому, кращий варіант — скористатись послугами веб-студії. В штаті IT City — досвічені IT-фахівці різних напрямків. Це дозволяє нам комплексно вирішувати завдання в найкоротший можливий термін, з урахуванням всіх потреб замовника. Ми допоможемо в створенні надійного інструменту для успішного управління бізнесом — залишайте запит на зворотний дзвінок або телефонуйте за вказаним номером для отримання безкоштовної консультації.
</p>
                    
                    `
                },
                {
                    title: 'Доопрацювання функціоналу сайту',
                    subInfo: 'Опис послуги',
                    content: `
                    <p>
                    Доопрацювання та регулярне оновлення функціоналу сайту є обов’язковими для його якісної та ефективної роботи. Навіть якщо веб-розробник виконав поставлене завдання щодо створення веб-ресурсу «‎на відмінно» і на старті інтернет-проект задовольняє всі ваші очікування, в майбутньому, потреба модернізації неминуча.
</p>
<p><strong>Причини доопрацювання сайту</strong></p>
<p><b>Технічна:</b></p>
<ul>
<li>некоректне відображення сторінок на мобільних пристроях, девайсах із певною роздільною здатністю екрану;</li>
<li>необхідність інтеграції з зовнішніми сервісами або вивантаження інформації з 1С;</li>
<li>за результатами аудиту виявлено помилки в коді;</li>
<li>при наповненні контентом (фото-відеоматеріали, банери, інфографіки та ін.) знизилась швидкість завантаження сторінок.</li>
</ul>
<p><b>Маркетингова:</b></p>
<ul>
<li>розширився асортимент продукції або послуг;</li>
<li>інтернет-ресурс почав «‎просідати» у видачі;</li>
<li>Google впровадив нові алгоритми;</li>
<li>з’явився новий функціонал — способи зв’язку, доставки, відкриття офлайн-представництв та ін.;</li>
<li>збільшилось число відмов серед відвідувачів веб-порталу;</li>
<li>поточна інформація на сайті застаріла та потребує оновлення.</li>
</ul>
<p>
Інтерфейс, дизайн, функціонал — речі змінні і мають оновлюватись не тільки через впровадження нових маркетингових рішень чи виявлення технічних факапів. Просування онлайн-бізнесу — безперервний процес, який потребує всебічного розвитку та регуляторної підтримки, в тому числі і від веб-фахівців.
</p>
<p><strong>Види модернізації сайту</strong></p>
<ul>
<li><b>Hotfix.</b> Швидке виправлення помилок або внесення мінімальних коригувань (дрібні зміни в коді, внесення нового функціонального модуля). Виконується із дотриманням конкретного запиту замовника та чітко поставленим технічним завданням.</li>
<li><b>Комплексне оновлення функціоналу.</b> Може включати в себе редизайн, розширення версій сайту (наприклад, додавання інших мов чи мобільної версії), виправлення значних помилок роботи веб-ресурсу, заміну застарілих плагінів, запровадження нових програмних модулів та віджетів, перенесення даних на іншу CMS-систему без втрат трафіку.</li>
</ul>
<p><strong>Індивідуальні рішення від ІТ City</strong></p>
<p>Команда фахівців IT City швидко вирішить будь-які технічні питання задля ефективної роботи вашого веб-ресурсу. Ми пропонуємо повний спектр доробки сайтів — від дрібних коригувань коду чи внесення кількох графічних елементів на посадковій сторінці до комплексного аудиту та повного оновлення функціоналу. Не знаєте, яке рішення буде оптимальним для оптимізації вашого ресурсу? Ми допоможемо. Залишайте контакти у формі зворотнього зв’язку або телефонуйте за вказаним номером для отримання безкоштовної консультації.</p>

                    `
                },
                {
                    title: 'Виправлення помилок',
                    subInfo: 'Опис послуги',
                    content: `
                    <p>Помилки на сайті можуть виникнути на будь-якому етапі його розробки — починаючи від верстки, та закінчуючи кодами CMS. Це не тільки значно знижує юзабіліті веб-ресурсу, але й впливає на його ранжування пошуковими системами. Навіть якщо проблема непомітна для пересічного юзера, вона може стати причиною песимізації та поганого індексування сторінок. Не кажучи вже про критичні баги, які заважають коректній роботі ресурсу. Пропонуємо розглянути види помилок, їх причини та способи усунення.</p>
                    <p>
                    <b>Візуальні помилки.</b>Зазвичай, вони пов’язані з версткою чи контентом — не завантажуються фото, «їде» текст, відсутні заголовки, некоректно відображаються сторінки на пристроях із різною роздільною здатністю екрана та ін. Цей тип помилок — відносно простий та швидко вирішується виправленнями коду HTML, CSS або JavaScript.

</p>
<p>
<b>Повільне завантаження ресурсу.</b>
На показник швидкості сайту впливають чистота внутрішнього та зовнішнього коду, розміри та формати контенту, адаптивність дизайну, налаштування хостингу. Зазвичай, проводиться комплексний аудит, за результатами якого усуваються виявлені проблеми.
</p>
<p>
<b>Сайт не індексується пошуковими системами.</b> Це можливо, якщо веб-ресурс створений нещодавно або потрапив під фільтр пошуковика. В подібних випадках необхідно зробити пошуковий аудит за допомогою спеціалізованих онлайн-сервісів та, після, SEO-оптимізацію. Для досягнення кращих показників, як правило, веб-розробник працює в парі з SEO-фахівцем.
</p>
<p>
<b>«Помилка доступу» або окремі посадкові сторінки чи сайт загалом — не завантажуються.</b>
Серед типових причин: невірно налаштований 301 редирект, «биті» посилання, помилка SSL-сертифіката, збій підключення до бази даних, серверні баги, хакерські атаки, невдале оновлення та ін. Вирішується методом комплексного тестування та залучення профільних фахівців (системний адміністратор, front-end або back-end девелопери).
</p>
<p>
<b>Йде автоматичне перенаправлення на інший веб-рерус з мобільної або десктопної версії.</b> Найвірогідніше, на сайті вірус. Його необхідно знайти та видалити, а після покращити систему безпеки.
</p>
<p>
<b>Помилки бази даних.</b> Можливі за наступними ознаками: нечитабельність (символи замість тексту), відсутність контенту за запитом при внутрішньому пошуку на сайті, некоректна навігація (наприклад посилання вказує на новину, а при переході відображається вміст іншої або зовсім порожня сторінка). Причини різні — від непродуманої архітектури коду до SQL-ін'єкцій. Подібні проблеми слід вирішувати якнайшвидше, оскільки з розвитком ресурсу зростають і їх наслідки — в деяких випадках це може обернутися втратою даних.
</p>

<p><strong>Комплексний аудит та виправлення помилок в IT City</strong></p>
<p>Для коректного виправлення помилок сайту та запобігання їх появи в майбутньому, питання варто вирішувати комплексно. Фахівці команди IT City спеціалізуються на усуненні проблем всіх основних областей — візуальної частини, структури, загального функціоналу, безпеки, помилок в базі даних та в CMS, системних налаштувань хостингу.</p>
<p>При проведенні аудиту ми використовуємо автоматизовані сервіси перевірки ресурсів для швидкого та комплексного виявлення проблем. Попередньо, клієнт може отримати безкоштовну консультацію — залишайте запит на зворотний дзвінок онлайн або телефонуйте за вказаними номерами — ми оцінимо ваше питання та запропонуємо чітке рішення.</p>

                    `
                },
                {
                    title: 'Резервне копіювання та міграція',
                    subInfo: 'Опис послуги',
                    content: `
                    <p>Створення резервної копії — обов’язковий процес для якісної, безперервної роботи будь-якої сайту та гарантія безпеки вашого онлайн-бізнесу. Адже повністю захистити 
веб-ресурс від збоїв у роботі неможливо. Бекап допоможе швидко відновити всі дані в разі:
</p>
<ul>
<li>хакерської атаки або зараження вірусами;</li>
<li>випадкових помилок співробітників, що мають доступ до файлової системи;</li>
<li>проблем у роботі сайту після невдалих змін та доповнень, або оновлення CMS, до якої він підключений;</li>
<li>«падіння» серверу. Саме тому, крім автоматичного резервування файлів на хостингу (яке пропонують всі платні сервіси), варто робити ще й регулярні самостійні бекапи.</li>
</ul>
<p>
Ще один спосіб застосування резервних копій — створення їх тестових версій. Наприклад, якщо потрібно додати функціонал та перевірити чи не буде конфліктів з вже встановленими скриптами або плагінами. В такому випадку достатньо бекапу лише тих файлів, які будуть змінені, а не всього масиву інформації.
</p>
<p><strong>Особливості «самостійного» резервного копіювання</strong></p>
<p>
Всі дані вашого сайту зберігаються на сервері хостинг-провайдера. Роблячи бекап, ви копіюєте їх в інше місце. Додатковим «сховищем» може бути жорсткий диск, віддалений сервер іншого провайдера та навіть файлообмінник.
</p>
<p>
Зазвичай, зберігають не одну (останню) резервну копію даних, а відразу декілька — наприклад, за останній місяць. Іноді проблеми з порталом не відразу очевидні і відновлення попередньої копії ніяк не допоможе виправити помилки. Тож наявність навіть квартальних копій — не марна трата дискового простору.
</p>
<br>
<p><b>Частота бекапів</b></p>
<p>Для багатосторінкових сайтів із щоденними оновленнями інформації, бекап варто робити щодня, у випадку невеликого landing page достатньо періодичних копій після внесення коригувань.</p>
    <p>Окрім зазначених причин важливості резервних копій, без них ви також не зможете <b>мігрувати</b> на інший хостинг. Зазвичай, необхідність перенесення сайту виникає у власників масштабного онлайн-бізнесу — з розвитком веб-ресурсу. Але причини бувають різні:</p>
            <ul>
            <li>зміна доменного імені;</li>
            <li>небажання співпрацювати з компанією, яка сплачує податки країні-агресору;</li>
            <li>об’єднання декількох сайтів, що знаходяться на різних серверах; </li>
            <li>незадовільні умови поточного хостинг-провайдера: низький рівень швидкості, безпеки, неякісна робота служби підтримки, підняття вартості та ін.;</li>
            <li>недостатня пропускна спроможність сервера через ріст відвідуваності та кількості замовлень.</li>
            </ul>
            
            <p>
            «Переїзд» на інший веб-хостинг — складний та кропіткий процес, який потребує не тільки резервного копіювання, але й дотримання послідовності необхідних етапів: планування, підготовка, тестування, запуск, аудит сайту після міграції. Під час перенесення всіх даних, окрім технічної сторони, важливо врахувати й маркетингову. Наприклад, простежити, щоб у сторінок із високочастотним трафіком зберігся колишній URL та контент, мета-теги, або ж був вірно налаштований редирект. Це дозволить уникнути значного просідання позицій в пошукових системах.
</p>
<p>
Щоб резервне копіювання веб-ресурсу було регулярним та контрольованим, а процес міграції, у разі необхідності, продуманим, послідовним та без втрат трафіку, кращий варіант — звернутися до досвідчених ІТ-фахівців. Команда ІТ City гарантує:
</p>
<ul>
<li>швидке відновлення попередньої версії сайту у випадку форс-мажорів, а також вживання всіх можливих заходів для їх запобігання в подальшому;</li>
<li>повну автономію. Вам не потрібно буде хвилюватись за регулярність бекапів та за наявність вільного місця для зберігання даних;</li>
<li>технічну підтримку 24/7 та оперативне вирішення питань;</li>
<li>аудит сайту у поточного провайдера, підбір іншого хостингу з оптимальним для вас тарифним планом;</li>
<li>обговорення всіх умов перенесення, швидку та коректну міграцію;</li>
<li>підключення інтеграцій та їх налаштування, технічне консультування фахівців компанії;</li>
<li>комплексний аналіз сайту після міграції.</li>
</ul>
<p>
Щоб отримати безкоштовну консультацію з питань резервного копіювання даних або міграції, залишайте заявку на зворотний дзвінок, або телефонуйте за вказаним номером — ми допоможемо в безперебійній роботі вашого онлайн-ресурсу.
</p> 

                    
                    `
                },
                {
                    title: 'Швидкість роботи та безпека',
                    subInfo: 'Опис послуги',
                    content: `
                    <p>Швидкість завантаження сайту — один із основних факторів, що впливають на його позиції в видачі пошуковими системами, поведінку відвідувачів, їх лояльність до бренду та, в результаті — на прибутковість онлайн-бізнесу.</p>
                    <p>Низька продуктивність суттєво збільшує показник відмов. За дослідженнями DoubleClick, 53% користувачів мобільної версії сайту, залишають його вже після 3 секунд тривалості завантаження. Якщо час коректного відкриття посадкової сторінки 5-7 секунд, згідно зі статистикою Think with Google, вірогідність відмов зростає на 106%</p>
                    <p>Тобто, існує загальне правило — чим швидше контент з’являється на екрані, тим краще. Показники, на які варто орієнтуватися:</p>
                    
<ul>
<li>1 секунда — відмінний, але важкодосяжний результат;</li>
<li>2-3 секунди — оптимальний час для гарного юзабіліті ресурсу;</li>
<li>4-5 секунд — низька, але прийнятна швидкість;</li>
<li>6-8 секунд — поганий показник для якісного просування сайту;</li>
<li>від 9 секунд — критичний результат, який потребує термінових мір для підвищення швидкості завантаження.</li>
</ul>
<p>
Google рекомендує: завантаження найбільшого блоку інформації має відбуватись за 2- 2,5 секунди. Мобільної версії — до 3 секунд. Швидкість понад 4 секунди вважається менш ефективною — і для залучення потенційних покупців, і з точки зору seo-оптимізації.
</p>
<p>
Тривалість завантаження залежить від декількох факторів, їх можна розділити на внутрішні (серверні), та зовнішні (frontend). До перших відносять:
</p>
<p>
час відгуку від сервера; <br>
CMS сайту;<br>
локація користувача.
</p>
<p>Зі сторони видимої частини, на швидкість роботи впливають:</p>
<ul>
<li>HTML-код сторінок;</li>
<li>кількість та розмір файлів зображень;</li>
<li>об’єм файлів JS та CSS.</li>
</ul>
<p>
Для визначення показників часу завантаження, використовуються спеціалізовані онлайн-інструменти, а от покращити їх можна тільки «вручну», залучивши кваліфікованих IT-фахівців. Серед методів пришвидшення роботи ресурсу:
</p>
<ul>
<li>розрахунок та підбір необхідних потужностей сервера;</li>
<li>видалення зайвих плагінів;</li>
<li>використання кешування;</li>
<li>зменшення «ваги» графічного контенту;</li>
<li>оптимізація коду CSS та JavaScript;</li>
<li>налаштування попереднього завантаження критичних ресурсів;</li>
<li>використання розподіленої мережі серверів CDN.</li>
</ul>
<p>
Команда фахівців IT City допоможе в оптимізації часу завантаження вашого ресурсу. Ми перевіримо сайт за допомогою онлайн-сервісів, та на основі отриманих даних проведемо необхідний комплекс робіт для покращення результату.
</p>
<p>
Крім, того, ми можемо подбати про <b>безпеку</b> сайту. Щоб надійно захистити ваш онлайн-бізнес, ми пропонуємо комплексні рішення:
</p>
<ul>
<li><b>Оновлення ПО.</b>Усі доповнення, скрипти, плагіни мають бути ліцензованими та оновлюватись на регулярній основі, так як здебільшого, останні версії мають покращений захист.</li>
<li><b>Підключення SSL-сертифікатів.</b>SSL гарантує ефективне керування безпекою сайту за рахунок шифрування інформації. За відсутності протоколу SSL перехопити дані дуже легко. На його наявність вказує «https://» замість «http://» в адресному рядку браузера. </li>
<li><b>Створення резервних копій.</b>Періодичних бекапів потребують сайти навіть із високим рівнем безпеки. Наприклад, через можливий збій роботи сервера, незалежно від того, наскільки він надійний. Ми надаємо послугу резервного копіювання на регулярній основі, в залежності від частоти оновлення вашого ресурсу. </li>
<li><b>Обмеження доступу до адміністративної панелі за IP-адресою. </b>Така міра унеможливлює виконання входу в систему управління сайтом навіть із введенням вірних логіна та пароля, якщо IP-адреса користувача не вказана в системі.</li>
<li><b>Налаштування файлів конфігурації сервера.</b>озволяють підвищити рівень безпеки веб-ресурсу за рахунок блокування хотлінкінгу зображень, обмеження перегляду папок, закриття доступу до окремих файлів для всіх користувачів, крім адміністратора та ін.</li>
<li><b>Регулярний скринінг на наявність шкідливого коду.</b>Ми використовуємо сучасне, якісне ПО для систематичних перевірок веб-ресурсу на наявність погроз, відстеження сумнівної активності та запобігання можливих DDoS-атак.</li>
</ul>
                    `
                },
            ],
        },
        {
            categoryTitle:'Статті у блог:',
            articles:[
                {
                    title: 'Освіта на дивані: 10 переваг онлайн-навчання',
                    subInfo: 'Вимушений крок пошуку альтернатив очних уроків під впливом пандемії COVID-19, для багатьох українців став справжнім відкриттям — онлайн-освіта — це більш зручно, мобільно, та навіть дешевше. Адже час, потрібний на дорогу, можна витратити на свої особисті потреби. При чому, оцінили переваги нового формату здобуття знань, як викладачі, так і учні...',
                    content:`
                    <p>
                    Вимушений крок пошуку альтернатив очних уроків під впливом пандемії COVID-19, для багатьох українців став справжнім відкриттям — онлайн-освіта — це більш зручно, мобільно, та навіть дешевше. Адже час, потрібний на дорогу, можна витратити на свої особисті потреби. При чому, оцінили переваги нового формату здобуття знань, як викладачі, так і учні. Пропонуємо розглянути їх більш детально.
</p> 
<p><strong>Для організаторів</strong></p>
<ol>
    <li> 
    <b>Більш комплексна подача матеріалів.</b> <br>
    Онлайн-освітній процес не обмежується лише записами на дошці, книгами та конспектами. Використання різноманітних інтерактивних інструментів дозволяє прямо під час уроку демонструвати презентації, вмикати відео, ділитися корисними посиланнями.

    </li> <br>
    <li>
    
    <b>Максимум концентрації на лекціях та практичних.</b>
    Під час онлайн-занять, викладач не відводить свою увагу на запізнення учнів, розмови в аудиторії та інші фактори. І навіть, якщо хтось підключається вже посеред уроку, це не відволікає інших.
</li> <br>
    <li>
    
    <b>Автоматизоване оцінювання.</b>
    Із претензіями с приводу «свідомого заниження оцінок» в очному навчанні стикався, мабуть, кожен викладач. Онлайн-тести та інтернет-системи перевірки дають об’єктивну оцінку рівня знань та дозволяють запобігти багатьох, часто хибних, скарг від учнів або їх батьків.

</li> <br>
    <li>
    
    <b>Економія часу.</b>
    І не тільки на щоденних зборах на роботу чи дорозі. В онлайн-режимі можна не повторювати кілька разів рішення задач чи диктувати конспекти — кожен, хто навчається, знайде відповіді на актуальні запитання в відеозаписі, а всі необхідні теми із поясненнями отримає у вигляді презентацій.

</li> <br>
    <li>
    
    <b>Проявити себе — легше.</b>
    В епоху діджиталізації бюрократичні методички поступово відходять на другий план. Звичайно, дотримання навчальної програми ніхто не скасовує, але в онлайні поле для демонстрації свої знань та навичок більш широке — через цікаві візуалізації, авторські тексти, відео-та фотопідбірки тощо.

</li>
</ol>
<p ><strong>Для учнів</strong></p>
<ol>
<li>
<b>Комфорт.</b>
Незручні парти та холодні аудиторії — в минулому. Бажано дотримуватись базового дрес-коду і в онлайн-відео підключеннях, але через вебку навряд чи можна почути докори щодо чашки чаю на столі чи пледа на спинці крісла.

</li><br>
<li>
<b>Зниження витрат.</b>
Вигода помітна всім. Батьки школярів економлять на традиційному приладді — підручниках для самостійного опрацювання, канцелярських дрібницях і постійних потребах «на ремонт класу». Студенти можуть вступити у ВНЗ в іншому місті без необхідності виїжджати з дому, знімати квартиру або платити за гуртожиток. Не варто перейматись й через низьку ціну вузькопрофільних онлайн-курсів (мовних, IT, креативних, маркетингових). Ніякого підвоху немає — економія на оренді, облаштуванні учбового простору, гаджетах дає можливість пропонувати більш дешеве, але якісне навчання.

</li><br>
<li>
<b>Відсутність емоційної напруги.</b>
Дистанційна освіта — мабуть, одне із кращих нововведень галузі для інтровертів. Адже тут немає розподілень на комунікабельних та сором'язливих. Перші можуть висловити свою думку ввімкнувши вебку та мікрофон, а несміливі — написати її в онлайн-чаті. 

</li><br>
<li>
<b>Краще засвоєння вивченого.</b>
Одна з основних переваг онлайну — можливість опановувати знання у зручному темпі, але із дотриманням заданого плану. Навчальні онлайн-платформи дозволяють переглядати запис уроків та презентації стільки разів, скільки потрібно для засвоєння нової інформації. Окрім того, є доступ до чату із викладачем, для індивідуальних уточнень всіх питань. Тоді як очна форма обмежує часом перебування в аудиторії.

</li><br>
<li>
<b>Максимальна мобільність. </b>
Здобувати знання онлайн легко будь-де: за кордоном, на дачі, у відрядженні, та, навіть в автомобільному заторі — головне мати стабільний інтернет. Почувати себе вільно — хіба не про це ми мріяли за партами в школі та університеті?

</li>
</ol>

<p><b>Є і ложка дьогтю</b></p>
<p>Попри очевидні аргументи на користь дистанційної освіти, суперечки «за» та «проти» онлайн-навчання набирають обертів. Негативні відгуки залишають переважно викладачі старшого покоління, які не один десяток років ділилися своїми знаннями у «стаціонарному» режимі. Серед основних недоліків, вчителі виділяють переважно «технічні»:</p>
               <ul>
               <li>
               Потрібно адаптувати звичну методику «під онлайн», адже способи утримання уваги учнів на очних заняттях та перед монітором — відрізняються.
</li><br>
<li>
Потребують «прокачки» навички володіння ПК — від швидкості набору тексту до опанування месенджерів, пошукових систем, графічних редакторів, сервісів створення презентацій.
</li><br>
<li>
Більш-менш сучасний та «швидкий» комп’ютер і доступ в інтернет — must have, без якого не відбудеться жодний урок. А для тих хто й сам навчався, й навчав інших виключно офлайн, навіть такі прості програми як Zoom або ж MyOwnConference можуть стати справжнім викликом й неабияким стресом. 
</li><br>
<li>
Ретельна організація лекцій, що потребує не менше часу, ніж підготовка до очних занять. Важливо не переплутати конспекти лекцій, викласти презентації у спільний доступ, вірно розіслати домашнє завдання, оцінити кожного учня, не пропустити важливі повідомлення.
</li><br>
</ul>  
<p>
Значно полегшує дистанційний освітній процес і вирішує майже всі можливі технічні труднощі навчальна онлайн-платформа — зручний простір для лекторів та учнів. Головна її особливість — комплексне закриття всіх важливих, для комфортного здобуття знань, питань. Простими словами, це програмне забезпечення для організації взаємодії між користувачами, адаптоване під освітні заклади.
</p>
<p>
Щоб ефективно керувати навчальним процесом, не потрібно інсталювати додатки чи програми на сотні мегабайтів — достатньо зареєструватись та отримати логін та пароль для входу — завантажити домашні завдання, викласти актуальний розклад або внести необхідні коригування легко, навіть якщо немає змоги вийти в мережу з комп’ютера.
</p> <br>
<p style="text-align: center">Серед інших можливостей навчальних онлайн-платформ:</p>
<p>
– зручний онлайн-чат; <br>
– планер із функцією встановлення дедлайну;<br>
– перевірка знань учнів;<br>
– моніторинг успішності;<br>
– нагадування;<br>
– структуроване зберігання всіх освітніх матеріалів в одному місці.
</p>
<p>
Веб-розробка платформи включає в себе врахування індивідуальних особливостей освітнього закладу, а спектр функцій може бути розширеним за бажанням замовника.
</p>
                 
                    `
                },
                {
                  title:'Успішний інтернет-магазин',
                    subInfo: 'В епоху стрімкого розвитку технологій, відсоток онлайн-покупок з кожним роком росте. Згідно зі статистикою, 78% українців — активні користувачі інтернету, з них 33% — регулярно роблять замовлення в мережі. Та це тільки початок. За прогнозами, показник «диванних шопоголіків» буде лише збільшуватись...',
                    content: `
                    <p>
                  В епоху стрімкого розвитку технологій, відсоток онлайн-покупок з кожним роком росте. Згідно зі статистикою, 78% українців — активні користувачі інтернету, з них 33% — регулярно роблять замовлення в мережі. Та це тільки початок. За прогнозами, показник «диванних шопоголіків» буде лише збільшуватись. Із попитом зростає і кількість пропозицій — досвічені бізнесмени, і ті, хто тільки планує робити перші кроки в підприємництві, роздумуючи над напрямом своєї справи, роблять вибір на користь онлайн-продажів. Але як не «прогоріти» на старті та ефективно примножувати капітал? Розповідаємо 8 важливих технічних правил створення успішного інтернет-магазину.  
</p>
<p><strong>Самостійність — не завжди добре</strong></p>
<p>
Особливо, у веденні бізнесу. Коли ви — менеджер по закупці, бухгалтер, адміністратор і навіть веб-розробник. І якщо в перших трьох професіях ще можна відносно швидко розібратись самому (але недосвіченим підприємцям краще замовити хоча б консультації фахівців), то основам програмування ви навряд навчитесь за 1-2 тижні. Лише нахапаєтесь багів та технічних факапів. Тому, краще пориньте в організацію бізнесу, а розробку довірте профільним спеціалістам. Хоча це недешеве задоволення, кошти, вкладені на старті у якісний сайт, збережуть нерви та купу цінного часу у майбутньому.
</p>
<p><strong>Виділяйтесь</strong></p>
<p>
Звичайно, якщо прагнете із невеликого стартапу зрости до лідера онлайн-продажів в певній ніші товарів. Здобути справжню впізнаваність допоможе брендбук. Айдентика потрібна у всіх візуальних складових вашого бренду: від логотипа та унікального веб-дизайну на етапі створення сайту, до рекламних креативів та сувенірної (подарункової) продукції під час просування інтернет-магазину. І якщо вам це не здається пріоритетним — просто згадайте онлайн-гігантів — Amazon, Ebay чи хоча б українську «Розетку», які ще на початку своєї діяльності розробили «фірмовий» стиль та роками не змінюють його, вносячи лише незначні корективи. 
</p>
<p><strong>Будьте адаптовані для кожного веб-користувача</strong></p>
<p>
Понад 30% онлайн-покупців роблять замовлення через смартфон. За прогнозами <a href="https://www.businessinsider.com/">Business Insider</a> , в подальшому цифра буде лише зростати. То ж, одне з основних завдань продавця — адаптувати інтернет-магазин під всі типи екранів смартфонів, планшетів, ноутбуків. Найкращий спосіб перевірки мобільності ресурсу — тестування із різних пристроїв. Наявність декількох гаджетів не обов’язкова — досвічені розробники знають як це зробити за допомогою веб-інструментів.
</p>
<p><strong>Приділіть особливу увагу юзабіліті сайту</strong></p>
<p>
Головна задача будь-якого інтернету-магазину — це продажі. Тому питання юзабіліті — пріоритетне для залучення потенційних покупців. З перших секунд відвідування вашого сайту, людям має бути зрозуміло, де шукати потрібний товар, як зробити покупку, здійснити оплату, зв’язатись з менеджером. Якщо в інтерфейсі важко розібратись навіть вам — навряд чи це захоче робити звичайний «перехожий» користувач. Оптимальний варіант перевірити юзабіліті — запитати про це у своєї ЦА. Створіть для перших покупців просте опитування: поцікавтесь про зручність функціоналу, дізнайтесь про якість доставки та самого товару — так ви отримаєте найактуальнішу інформацію, що варто покращити, й підвищите лояльність клієнтів.
</p>
<p><strong>Змістовно інформуйте про товар</strong></p>
<p>
Один із основних факторів, який зупиняє веб-користувачів робити онлайн-покупки — відсутність можливості фізично взаємодіяти з товаром: доторкнутися, приміряти, перевірити функціональність. То ж картка товару має включати в себе максимум даних. Окрім базових характеристик, додайте декілька актуальних фото в різних ракурсах, створіть відеоогляд, вкажіть про гарантійні умови від виробника та магазину. Влучна опція — можливість поставити питання на сторінці товару, щоб відповіді були доступні всім юзерам. Враховуйте, зворотний зв’язок має бути швидким та містким. Відповідь через декілька днів: «виробником не вказано», навряд чи влаштує покупця.
</p>
<p><strong>Не заощаджуйте на CMS</strong></p>   
<p>Якісна CMS забезпечує швидку та зручну роботу в адміністративній панелі, а також потрібну функціональність та технічні можливості веб-ресурсу. Але майте на увазі, що платформа надає лише управління типовим функціоналом. Тобто, ви можете самостійно контролювати замовлення, додавати нові позиції, статті, банери. Але це не виключає розробку унікального дизайну та додаткових опцій саме під ваші задачі.</p>
          <p><strong>Оптимізуйте час покупця</strong></p>  
          <p>Головна особливість онлайн-покупок: швидкість та гнучкість. Не потрібно збиратись, витрачати час на дорогу, годинами блукати по ТЦ в пошуках потрібного товару: шопитись не встаючи із дивану можна навіть вночі. Тому ретельно пропрацюйте ці переваги. Налаштуйте функцію «покупки в один клік», без обов’язкової реєстрації та заповнення об’ємних форм із контактами. Додайте розділ супутніх товарів, де до основного пропонуйте комплектуючі та аксесуари. Організуйте можливість доставки на наступний день. Створіть «живий» чат, де покупець матиме змогу уточнити питання із фахівцем та дізнатись все, що його цікавить без необхідності чекати на дзвінок. Так, ви значно підвищите конверсію сайту та збільшите середній чек замовлення.</p>   
          <p><strong>Налаштуйте повідомлення</strong></p>    
          <p>
       Якщо користувач відвідав сайт, але не здійснив покупку, запропонуйте йому залишити свій e-mail або номер телефону, щоб ви змогли повідомити про наявність товару та зниження ціни. Так ви не тільки напрацюєте базу «теплих» клієнтів (адже людина сама вирішує надавати дані чи ні), але й зможете вчасно нагадати про себе способом ретаргетингу або через e-mail розсилки.   
</p> 
                    
                    `
                },
            ]
        },

    ],

    email:[
        {
            categoryTitle:'',
            articles:[
                {
                    title: 'Приклади розсилок',
                    subInfo: 'Туризм',
                    content: `
                    <p style="font-size: 20px; margin-bottom: 20px"><b><i>Всі тексти написані російською мовою згідно з вимогами маркетолога</i></b></p> <br>
                    <p><b>Тема від маркетолога: Призыв к покупке туров в экзотические страны</b></p>
                    <p>Вимоги: соблюдение темы, вовлекающий текст до 500 збп</p>
                    <p>Тема: Зимняя экзотика по жарким ценам</p>
                    <p>Путешествие на Мальдивы, Занзибар или в Доминикану — отличная возможность на время забыть о тяжелых пуховиках, морозе и пасмурных буднях! И хоть теплая погода здесь царит круглый год, именно зимой устанавливаются идеальные условия для пляжного отдыха. Легкий морской бриз, мягкий песок на берегу, живописная природа вокруг — что может быть лучше в холодную пору года? Тем более, что провести зимние каникулы в экзотическом уголке мира можно по очень доступной цене. Бронируйте туры прямо сейчас и поехали с нами в лето!
</p><br>
                    <p><b>Тема від маркетолога: Призыв к покупке горящих туров на НГ</b></p>
                    <p>Мечтаете провести зимние праздники у теплого моря, но не готовы потратить на путешествие весь семейный бюджет? Отдохнуть можно очень даже бюджетно, если поймать выгодный горящий тур. Широкий выбор предложений на Мальдивы, Шри-Ланку, в Турцию, Египет, ОАЭ, Доминикану, и другие страны по самым низким ценам — в «Поехали с нами». Скорее бронируйте путевку и собирайте чемоданы на море уже сейчас! 
</p><br>
                    <p><b>Тема від маркетолога: Призыв к покупке туров на НГ</b></p>
                    <p>Не знаете, как встретить Новый год? Одна из лучших идей — оставить на недельку-вторую холодную городскую зиму и перенестись в самое сердце праздника! Теплое море, яркое солнце, беззаботный релакс вас ждут в Египте, Доминикане, Мексике, Танзании, ОАЭ, на Мальдивах. Все атрибуты Нового года также в наличии: тематические ужины, феерические шоу-программы, салюты, и, конечно Санта. А зеленая пальма с гирляндами смотрится не хуже елки.</p>
                    <p>Приобретайте туры на праздничные даты и поехали с нами за незабываемыми впечатлениями!
</p><br>
                    <p><b>Тема від маркетолога: Поздравить клиентов с Новым годом</b></p>
                    <p>Долгожданный праздник уже на пороге…</p>
                    <p>В Греции принято в новогоднюю ночь разбивать на пороге дома гранат: сколько зернышек разлетится по сторонам — столько удачных дней будет в наступающем году. Желаем, чтобы в разбитом Вами гранате было не менее 365 зернышек! Побольше ярких впечатлений, солнечного настроения, ежедневных открытий, незабываемых поездок и покорения новых вершин! Пусть грядущий год подарит Вам друзей, с которыми можно объехать весь мир, а мы поможем Вам организовать путешествие Вашей мечты!
От всей души благодарим Вас за доверие и интерес к тому, что мы делаем. С Новым годом!
</p><br>
                    <p><b>Тема від маркетолога:  Призыв к раннему бронированию туров на майские праздники
</b></p>
                    <p>Почему бы не спланировать весеннее путешествие уже сейчас?
</p>
                    <p>Весна — лучшая пора для поездок в новые страны. Волшебные ароматы цветущих деревьев, теплые городские променады, посиделки на набережной с чашечкой кофе и традиционной уличной едой. А еще — много праздничных выходных. Соскучились по завораживающему шуму волн и веселым активностям в море? Поехали с нами открывать купальный сезон с максимальной выгодой. Бронируйте туры на майские праздники уже сейчас со скидкой до 40% и организуйте себе незабываемый мини-отпуск. 
                    </p><br>
                    <p><b>Тема від маркетолога: Призыв к покупке туров в Дубай</b></p>
                    <p>Тема: А погнали в отпуск в роскошные Эмираты вполовину дешевле?!</p>
                    <p>Дубай способен удивить даже самых искушенных путешественников: крупнейшие моллы, самые высокие небоскребы, роскошные дворцы, впечатляющие шоу фонтанов и….оазис настоящего умиротворения посреди шумного мегаполиса — белоснежное, песчаное побережье, где хочется расслабляться дни напролет! Большинство зон отдыха обладают «Голубым флагом» — гарантией чистоты и безопасности. Совместите увлекательные прогулки и пляжный релакс с выгодой для кошелька. Именно в начале весны путевки в Дубай предлагаются по самой низкой цене! Бронируйте выгодные туры — в сети «Поехали с нами».
</p><br>
                    <p><b>Тема від маркетолога: Туры в Кению </b></p>
                    <p>Известное выражение «magical Kenya» вовсе не рекламный ход.</p>
                    <p>Здесь гармонично сочетаются первозданные просторы национальных заповедников и современные пляжные курорты с отлично налаженной инфраструктурой. Самобытность местного народа с разнообразием развлекательной программы для туристов. Захватывающее сафари, дайвинг, водные активности, и, конечно, релакс на великолепных белоснежных пляжах.
</p>
                    <p>Отдых в Кении доступен для украинцев с 16 апреля! А для максимального комфорта — прямые чартерные рейсы из Киева в самое сердце Африки! Бронируйте туры уже сейчас в сети «Поехали с нами».
</p><br>
                    <p><b>Тема від маркетолога:  Туры на 8 марта в Стамбул. Экскурсии.
</b></p>
                    <p>Как удивить любимую женщину 8 марта? Яркое путешествие — беспроигрышный вариант! А тем более, когда речь идет о Стамбуле. Запах специй и свежей выпечки, захватывающие виды на Босфор, живописные парки, величественные дворцы и мечети — особенная атмосфера здесь витает в каждом уголке. Кстати, не забудьте присмотреть уютный рыбный ресторанчик для праздничного ужина. А мы поможем забронировать лучшее предложение для незабываемой поездки. Оставляйте запрос на подбор тура на сайте «Поехали с нами».
                    </p><br>
                    <p><b>Тема від маркетолога: Призыв к покупке горящих туров</b></p>
                    <p>Для многих людей, желающих побывать за границей, стоимость отдыха является решающим фактором в пользу того, чтобы перенести поездку «до лучших времен». 
</p>
                    <p>Осуществите свою мечту, и посетите самые живописные уголки мира с выгодой для кошелька. Покупка горящих туров — лучший вариант экономии и отличная возможность насладиться отдыхом, даже обладая ограниченным бюджетом. Бронируйте спецпредложения со скидкой до 50% и поехали с нами за новыми впечатлениями!
</p><br>
                    <p><b>Тема від маркетолога: Отдых в Турции без тестов</b></p>
                    <p>Ура! Отличные новости! Отпуск в любимой Турции стал ещё доступнее!
</p>
                    <p>Теперь путешествовать на солнечные морские курорты, в колоритный Стамбул, сказочную Каппадокию и другие регионы восточной страны можно без ПЦР-тестов! С 15 мая украинцам не нужно проходить диагностику на наличие COVID-19 за несколько дней до вылета, вместе с тем, можете рассчитывать на максимальную безопасность и заботу о вашем здоровье. Все санитарно-эпидемиологические правила в турецких отелях строго соблюдены. Отдыхайте с комфортом и без ограничений вместе с «Поехали с нами»!
</p><br>
                    <p><b>Тема від маркетолога: Призыв отметить День святого Валентина со своей половинкой на пляже</b></p>
                    <p>Главный праздник всех влюбленных совсем скоро. Как впечатлить близкого человека и организовать незабываемый День святого Валентина?! Порадуйте себя и свою вторую половинку отдыхом в жаркой стране. Раскидистые пальмы, морские волны и атмосфера уединения как нельзя лучше способствуют романтическому времяпровождению и признаниям любви. 
</p>
                    <p>Райские Мальдивы, Доминикана, ОАЭ или же Египет — независимо от того, какое направление вы выберете, поездка подарит массу совместных впечатлений и позволит еще больше сблизиться с любимым человеком. Лучшие туры для двоих — в сети «Поехали с нами».
</p>
                    <br> <br>    
                    <p><i>Більше прикладів – на запит.</i></p>
                    
                    `,
                },
            ]
        }
    ],



    smm:[
        {
            categoryTitle:'',
            articles:[
                {
                    slideShow:{
                        title:'NailSpace_Kyiv',
                        slides:[
                            {url:'images/insta/nails/FireShot_Capture_001.png', top:-100},
                            {url:'images/insta/nails/FireShot_Capture_002.png', top:-150},
                            {url: 'images/insta/nails/FireShot_Capture_003.png', top: -185},
                            {url: 'images/insta/nails/FireShot_Capture_004.png', top: -160},
                            {url: 'images/insta/nails/FireShot_Capture_005.png', top: -150},
                            {url: 'images/insta/nails/FireShot_Capture_006.png', top: -150},
                            {url: 'images/insta/nails/FireShot_Capture_007x.png', top: -195},
                            // {url: 'images/insta/nails/FireShot_Capture_008.png', top: -215},
                            {url: 'images/insta/nails/FireShot_Capture_023.png', top: -190},
                        ],
                    },
                },
                {
                    slideShow:{
                        title:'«Ветеринарний дім»‎',
                        slides:[
                            'images/insta/zoo/FireShot_Capture_014x.png',
                            'images/insta/zoo/FireShot_Capture_015.png',
                            'images/insta/zoo/FireShot_Capture_016.png',
                            'images/insta/zoo/FireShot_Capture_017.png',
                            'images/insta/zoo/FireShot_Capture_018.png',
                            'images/insta/zoo/FireShot_Capture_019.png',
                            'images/insta/zoo/FireShot_Capture_020.png',
                            'images/insta/zoo/FireShot_Capture_024.png',
                        ],
                    },
                },
                {
                    slideShow:{
                        title:'«Поїхали з нами»',
                        slides:[
                            'images/insta/poehali/FireShot_Capture_021.png',
                            'images/insta/poehali/FireShot_Capture_025.png',
                            // 'images/insta/poehali/FireShot_Capture_026.png',
                            {url: 'images/insta/poehali/FireShot_Capture_027.png', top: -43},
                            'images/insta/poehali/FireShot_Capture_028.png',
                            'images/insta/poehali/FireShot_Capture_029.png',
                            'images/insta/poehali/FireShot_Capture_030.png',
                            'images/insta/poehali/FireShot_Capture_031.png',
                            'images/insta/poehali/FireShot_Capture_034.png',
                        ],
                    },
                },
            ]
        }
    ],
    about:[
        {
            categoryTitle:'',
            articles:[
                {
                    about: `
                    <p class="title">Ключові знання та навички:</p>
                    <ul>
                        <li>написання текстів різного формату (SEO, LSI, SMM, описи, відгуки, лонгріди в блог та ін.);</li>
                        <li>редактура;</li>
                        <li>розуміння принципів SEO-оптимізації;</li>
                        <li>HTML, базові знання CSS;</li>
                        <li>володіння фото-та відеоредакторами (Сanva, Figma, InShot, СapCut, SnapSeed, VSCO, Lightroom та ін), робота в планерах (Unfold, Preview);</li>
                        <li>обробка та базовий монтаж відео для соцмереж;</li>
                        <li>сторісмейкінг;</li>
                        <li>знання рекламного кабінету AdsManager, розуміння принципів налаштування таргету;</li>
                        <li>досвід складання контент-плану та структурованих ТЗ;</li>
                        <li>досвід роботи за методологією SCRUM;</li>
                        <li>робота з онлайн-сервісами перевірки текстів.</li>
                    </ul> <br>
                    <p class="title">Володіння мовами:</p>
                    <p style="padding-left: 20px;margin-bottom: 10px;"> Англійська — Upper-Intermediate <br> Російська — вільно <br> Українська — рідна</p>
                    <br>
                    <p class="title" style="margin-bottom: 20px">Досвід роботи:</p>
                       
                    <p class="sub-title">&#8251; Контент-редактор</p>
                    <p style="margin-bottom: 15px;">  «Поїхали з нами‎‎» <span style="font-size: 16px;">(09.2018 - 02.2022)</span></p>
                    

<p><u>Менеджмент команди віддалених копірайтерів:</u></p>
<ul>
<li>пошук та навчання нових співробітників;</li>
<li>щомісячний розрахунок виплат;</li>
<li>складання та розподіл між копірайтерами структурованих ТЗ;</li>
<li>контроль виконання завдань.</li>
</ul> 
<p><u>Редагування текстів:</u></p>
<ul>
<li>вичитка та внесення зауважень для правок копірайтером або самостійне редагування текстів згідно із мовними нормами та вимогами seo-спеціаліста.</li>
</ul>
<p><u>Копірайтинг:</u></p>
<ul>
<li>написання текстів різного формату: відгуків, описів, seo-текстів, статей в блог, PR-статей, для e-mail розсилок, соцмереж, landing page та ін.</li>
</ul>
<p><u>Робота з контентом на сайті:</u></p>
<ul>
<li>переклад сторінок з російської на українську;</li>
<li>актуалізація інформації по готелям;</li>
<li>робота із html-блоком та мета-тегами;</li>
<li>підбір графічного контенту.</li>
</ul>
<br>
<p class="sub-title">&#8251; SMM-копірайтер <span style="font-weight: 500;">(фріланс)</span></p>
<p style="margin-bottom: 15px;">«Ветеринарний дім» <span style="font-size: 16px">(09.2021-12.2021)</span></p> 
 <ul>
 <li>Написання постів для Instagram згідно із Ton of voice;</li>
 <li>підбір та обробка фото;</li>
 <li>ситуативне розміщення сторіс.</li>
 </ul>
<br>
<p class="sub-title">&#8251; Контент-менеджер</p>
<p style="margin-bottom: 15px;">Doc.ua <span style="font-size: 16px">(12.2017 - 09.2018)</span> </p> 
<ul>
<li>Редагування/розміщення анкет лікарів, медичних закладів;</li>
<li>створення та розміщення акційних пропозицій на сайті;</li>
<li>робота з прайс-листами: додавання та актуалізація цін медичних установ;</li>
<li>написання SEO-текстів та статей у блог;</li>
<li>підбір та обробка графічного контенту.</li>
</ul>
<br>
<p class="sub-title">&#8251; Контент-менеджер, адміністратор інтернет-магазину</p>
<p style="margin-bottom: 15px;">«Лапишоп» <span style="font-size: 16px">(08.2016 -12.2017)</span> </p> 
<ul>
<li>Робота з картками товарів (створення нових, редагування вже створених);</li>
<li>контроль цін та наявності товарів на сайті;</li>
<li>робота з прайс-листами постачальників, актуалізація цін на сайті;</li>
<li>завантаження акцій на сайт;</li>
<li>написання статей у блог, підбір тематичних фото;</li>
<li>прийом та обробка замовлень, консультація клієнтів;</li>
<li>контроль оплати та доставки замовлень;</li>
<li>комунікація із дистрибьюторами;</li>
<li>робота тз логістичним відділом; складання маршрутних листів для кур'єрів;</li>
<li>робота в CMS Magento.</li>
</ul>
                    `,
                }
            ],

        }
    ],
}

export default contentData